import { PlayerContext } from "@/components/player/player-wrapper";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { countUpBySeconds } from "@/lib/utils/countUp"
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { hasData } from "@/lib/utils/hasData";
import { timerFormat } from "@/lib/utils/timerFormat"
import { useContext, useState } from "react";

export default function PostTranscribe({
     transcribe_json
    ,file
    ,duration
    ,post_id
    ,content_id
    ,content_table
    ,owner_id
    ,owner_name
    ,avatar_url
    ,broadcast_title
    ,start_date
    ,router
    ,clip_state
    ,set_clip_state
    ,openModal
}) { 


    const[buffer] = useState(1)
    const [clip_in,set_clip_in] = useState(null)
    const [clip_out,set_clip_out] = useState(null)
    const {player_state,set_player_state} = useContext(PlayerContext)

    // const [active_sentence,set_active_sentence] = useState({seek:0,index:`0-0`})


    
    const startPlayerTimestamp = async (timestamp) => {

        await set_player_state({});

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:true
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        }

        // console.log("player_object",player_object,display_data)

        await set_player_state(player_object);

    }

    return (<>
        <div className="p-4">

            {/* STARTS */}
            <TranscribeSpeaker
                paragraph={{start:0,end:0,num_words:0,speaker:"Transcript Starts",sentences:[{start:0,end:0,text:""}]}}
                startPlayerTimestamp={startPlayerTimestamp}
                player_state={player_state}
                set_player_state={set_player_state}
                clip_in={clip_in}
                set_clip_in={set_clip_in}
                clip_out={clip_out}
                set_clip_out={set_clip_out}
                set_clip_state={set_clip_state}
                openModal={openModal}
                buffer={buffer}
                duration={duration}
            />

            {/* TRANSCRIPT LOOP */}
            {transcribe_json?.map((paragraph, pindex) => {

                
                return (<TranscribeSpeaker
                            key={pindex}
                            paragraph={paragraph}
                            startPlayerTimestamp={startPlayerTimestamp}
                            player_state={player_state}
                            set_player_state={set_player_state}
                            clip_in={clip_in}
                            set_clip_in={set_clip_in}
                            clip_out={clip_out}
                            set_clip_out={set_clip_out}
                            set_clip_state={set_clip_state}
                            openModal={openModal}
                            buffer={buffer}
                            duration={duration}
                        />)


            })}

            {/* ENDS */}
            <TranscribeSpeaker
                paragraph={{start:duration,end:duration,num_words:0,speaker:"Transcript Ends",sentences:[{start:duration,end:duration,text:""}]}}
                startPlayerTimestamp={startPlayerTimestamp}
                player_state={player_state}
                set_player_state={set_player_state}
                clip_in={clip_in}
                set_clip_in={set_clip_in}
                clip_out={clip_out}
                set_clip_out={set_clip_out}
                set_clip_state={set_clip_state}
                openModal={openModal}
                buffer={buffer}
                duration={duration}
            />
            
        </div>

    </>)
}

function TranscribeSpeaker({
    paragraph,
    startPlayerTimestamp,
    player_state,
    set_player_state,
    clip_in,
    set_clip_in,
    clip_out,
    set_clip_out,
    set_clip_state,
    openModal,
    buffer,
    duration
}) {
    return (<div className=" text-left font-bold" >
                <div className={`flex items-center content-center`}>
                    <div className={`flex-1
                    
                    ${((hasData(clip_in) && hasData(clip_out)) //make sure user is trying to clip at all
                                                                && (paragraph?.sentences[0].start > clip_in)
                                                                && (paragraph?.sentences[0].end <= clip_out ))
                                                                ? `bg-gray-700`
                                                                : ``
                                                            }`}>
                    {["Transcript Starts","Transcript Ends"]?.indexOf(paragraph.speaker) == -1 ? "Speaker " : ""}{paragraph.speaker}
                    </div>
                    {["Transcript Starts"]?.indexOf(paragraph.speaker) > -1 &&
                    <div className="pb-1">
                     Clip Options
                    </div>
                    }
                    {/* clip length */}
                    {((hasData(clip_in) && hasData(clip_out)) 
                            && (paragraph?.sentences[0].start > clip_in)
                            && (paragraph?.sentences[0].end <= clip_out )) &&
                    <ClipDivot/>
                    }
                </div>
                

                                    {paragraph.sentences.map((sentences, sindex) => {

                                        // if (active_sentence?.seek < player_state?.seek 
                                        //     && active_sentence?.seek >= Math.round(sentences.start)) {
                                        //     set_active_sentence({seek:player_state?.seek,index:`${pindex}-${sindex}`})
                                        // }

                                        return (<TranscribeSentence
                                            key={sindex}
                                            sentences={sentences}
                                            startPlayerTimestamp={startPlayerTimestamp}
                                            player_state={player_state}
                                            set_player_state={set_player_state}
                                            clip_in={clip_in}
                                            set_clip_in={set_clip_in}
                                            clip_out={clip_out}
                                            set_clip_out={set_clip_out}
                                            set_clip_state={set_clip_state}
                                            openModal={openModal}
                                            buffer={buffer}
                                            duration={duration}
                                        />)
                                    })}
                        </div>)
}

function TranscribeSentence({
    sentences,
    startPlayerTimestamp,
    player_state,
    set_player_state,
    clip_in,
    set_clip_in,
    clip_out,
    set_clip_out,
    set_clip_state,
    openModal,
    buffer,
    duration
}) {
    return (<div className={`font-normal flex items-center content-center group
    ${((hasData(clip_in) && hasData(clip_out)) //make sure user is trying to clip at all
                                                                && (sentences.start >= clip_in)
                                                                && (sentences.end <= clip_out ))
                                                                ? `bg-gray-700`
                                                                : ``
                                                            }
    `} 
                                        
                                                
                                        >
                                            
                                                    <div className={`px-2 text-blue-400 underline hover:no-underline cursor-pointer
                                                    
                                                    
                                                    `}
                                                    
                                                        onClick={()=>{ 
                                                            startPlayerTimestamp(sentences.start)
                                                        }}
                                                    >
                                                        {timerFormat(countUpBySeconds(Math.round(sentences.start)))}
                                                    </div>
                                                    <div 
                                                        className={`flex-1 text-left cursor-pointer hover:opacity-90
                                                            
                                                            ${player_state?.seek > sentences.start
                                                                ? `text-blue-400`
                                                                : ``
                                                            }
                                                        `}
                                                    onClick={()=>{ 
                                                        startPlayerTimestamp(sentences.start)
                                                    }}
                                                    >
                                                        {hasData(sentences.text) ? sentences.text : <span className="opacity-0">.</span>} 
                                                    </div>

                                                    {/* CLIPPER */}
                                                    <div className="relative flex items-center content-center ">
                                                        
                                                        <div 
                                                            className={`px-2 w-[104px] text-center rounded-sm absolute right-0 whitespace-nowrap ${(hasData(clip_in) && clip_in !== sentences.start) ? 'group-hover:invisible' : 'group-hover:visible'} ${clip_in == sentences.start ? 'visible': 'invisible'} cursor-pointer  bg-green-600 hover:bg-green-500 text-white`}
                                                            onClick={()=>{
                                                                //set_clip_state(prev=>{return {...prev,start:sentences.start}})
                                                                if (hasData(clip_in)) {
                                                                    if (clip_in == sentences.start) {
                                                                        set_clip_in(null);
                                                                    } else {
                                                                        set_clip_in(sentences.start)

                                                                        //reset clip_out if it's now before clip_in
                                                                        if (hasData(clip_out) && (clip_out <= sentences.start)) set_clip_out(null);
                                                                    }
                                                                } else {
                                                                    set_clip_in(sentences.start)
                                                                    //reset clip_out if it's now before clip_in
                                                                    if (hasData(clip_out) && (clip_out <= sentences.start)) set_clip_out(null);
                                                                }

                                                                
                                                            }}
                                                        >
                                                            set clip start
                                                        </div>

                                                        {/* clip length */}
                                                        {((hasData(clip_in) && hasData(clip_out)) 
                                                                && (sentences.start > clip_in)
                                                                && (sentences.end < clip_out)) &&
                                                            <ClipDivot/>
                                                        }

                                                        
                                                        {(hasData(clip_in) && clip_in !== sentences.start) &&
                                                        <div 
                                                            className={`px-2  w-[104px] text-center rounded-sm absolute  right-0 whitespace-nowrap ${(hasData(clip_out) && clip_out !== sentences.end) ? 'group-hover:invisible' : 'group-hover:visible'} ${clip_out == sentences.end ? 'visible': 'invisible'} cursor-pointer bg-red-600 hover:bg-red-500 text-white`}
                                                            onClick={()=>{
                                                                if (hasData(clip_out)) {
                                                                    
                                                                    if (clip_out == sentences.end) {
                                                                        set_clip_out(null);
                                                                    } else {
                                                                        set_clip_out(sentences.end)
                                                                        //reset clip_in if it's now after clip_out
                                                                        if (hasData(clip_in) && (sentences.end < clip_in)) set_clip_in(null);
                                                                    }
                                                                } else {
                                                                    set_clip_out(sentences.end)
                                                                    //reset clip_in if it's now after clip_out
                                                                    if (hasData(clip_in) && (sentences.end < clip_in)) set_clip_in(null);
                                                                }

                                                                
                                                            }}
                                                        >
                                                            set clip end
                                                        </div>
                                                        }

                                                       

                                                        
                                                        {(hasData(clip_in) && hasData(clip_out)) &&
                                                        <button 
                                                            className={`bg-blue-600 hover:bg-blue-500 font-bold z-[100] text-lg text-white px-2 py-1 rounded-md whitespace-nowrap cursor-pointer absolute shadow-md right-0 mt-20  ${(clip_out == sentences.end) ? 'block': 'hidden'} cursor-pointer`}
                                                            onClick={()=>{

                                                                let this_clip_in = hasData(clip_in) ? clip_in : 0
                                                                let this_clip_out = hasData(clip_out) ? clip_out : duration

                                                                set_player_state(prev=>{return {...prev,isPlaying: false}})
                                                                set_clip_state({status:1,auto:0,start:this_clip_in-buffer,track:this_clip_in-buffer,end:this_clip_out+buffer});
                                                                openModal("clip",0,13);
                                                               
                                                            }}    
                                                        >
                                                            Open Clip Editor
                                                        </button>
                                                        }
                                                    </div>
                                                    
                                                    
                                                    
                                        </div>)
}

function ClipDivot({}) {
    return (<div
                className="bg-gray-700  items-center content-center hidden xs:flex"
                >
                <div className="w-[104px] flex items-center content-center justify-end text-center mx-auto animate-pulse">
                    <SvgJsx 
                        type={'fill'}
                        icon={"chevron-double-down-sm"}
                        className={`h-6 w-6 text-gray-500 `}
                        title={"Clip"}
                    />
                </div>
            </div>)
}