import { hasData } from "./hasData";

export const extractJson = (json,type) => {
    if (!hasData(json)) return '';
    let e = JSON.parse(json).filter((e)=>hasData(e?.url))[0]

    if (type == "start") return e?.start_date
    if (type == "end") return e?.end_date
    if (type == "duration") return e?.duration
    if (type == "url") return e?.url //?.replace(`${e?.hash}.${e?.extention}`,'')
                             
}
       