import {CopyToClipboard} from 'react-copy-to-clipboard';
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { useEffect, useState } from 'react';

export default function ApiInstructionsCopy({
    copy_content
    ,display_content
    ,headline
    ,toast_message = `Copied ${headline} to clipboard!`
    ,toastFunction
    ,containerHeightClass
    ,showkey
    ,set_showkey
    ,bol_showbutton
    ,showbutton_text='key'
}) {

    const [isMounted,set_isMounted] = useState(false)
    const [show_copied,set_show_copied] = useState(false)

    useEffect(()=> {
        set_isMounted(true)
      },[])

      const copyFade = () => {
        set_show_copied(true)
        setTimeout(() => {set_show_copied(false)}, 2000);
      }

    return (<>
    <div className='my-5 w-full'>
        <div className="flex">
            <div className="flex-1">
                <div className="flex">
                    <div className="flex-0">
                        <h1 className="font-bold">
                            {headline}
                        </h1>
                    </div>
                    
                </div>
               
            </div>
            {bol_showbutton &&
                    <div className="flex-0">
                        
                        <a 
                            className='ml-2 text-xs whitespace-nowrap cursor-pointer underline px-2 py-1 rounded-md mr-2
                                hover:bg-gray-600'
                            onClick={()=>set_showkey(!showkey)}
                        >
                        {showkey ? `hide ${showbutton_text}` : `show ${showbutton_text}`}
                        </a>
                       
                    </div>
                     }
            <div className="flex-0">
                <CopyToClipboard 
                    text={`${copy_content}`}
                    onCopy={()=> {
                        toastFunction(toast_message);
                        copyFade();
                        }
                    }
                >   
                    <div className='mb-1 cursor-pointer flex items-center content-center px-2 py-1 border rounded-md shadow-sm
                                    text-gray-400 hover:text-gray-200 hover:border-gray-200 bg-gray-900
                                    '>
                    <SvgJsx 
                            type='outline'
                            icon={show_copied 
                                  ? 'check' 
                                  : 'clipboard'
                                }
                            className="w-5 h-5 flex-0"
                            title='Copy to Clipboard'
                        /> 
                        <div className="hidden xs:block flex-0 ml-1 text-xs">
                          {show_copied ? 'Copied' : 'Copy'}
                        </div>
                    </div>
                    </CopyToClipboard>  
            </div>
        </div>
        <CopyToClipboard 
        text={`${copy_content}`}
        onCopy={()=>{
                toastFunction(toast_message);
                copyFade();
                }
            }
        > 
        <div
            className={`${containerHeightClass} rounded shadow border resize-none p-2 text-sm focus:shadow-outline w-full overflow-x-hidden cursor-pointer
                        ${show_copied 
                            ? `bg-blue-600 text-white ` 
                            : `bg-gray-900 text-white`
                          }`}
        >
            <pre className="max-w-xs ">
                {display_content}
            </pre>
            
        </div>
        </CopyToClipboard>  
    </div>  
    </>)
}
