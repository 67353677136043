
import Loading from "@/components/templateux/loading/loading";
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import Tabs from "@/components/templateux/tabs";
import AuthorCredit from "@/components/templateux/user/author-credit";
import { config } from "@/lib/config";
import { getSlugFilter } from "@/lib/utils/getSlugFilter";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import pageint from "@/lib/utils/pageint";
import Link from "next/link";
import { useEffect, useState } from "react";
import ChatTimer from "../chat/chat-timer"

function PostLikes({
     post_id
     ,profiledata
    ,slug
    ,router
    ,cache
    ,set_cache
    ,openModal
}) {

    const page = pageint(router.query.page)
  var slugfilter = getSlugFilter(slug)


  const [filter, set_filter] = useState(slugfilter)
  const [filterinput, set_filterinput] = useState(slugfilter)
  const [filteropen, set_filteropen] = useState(false)
  const csort = router.query.sort || config.itemsort
  const [sort, set_sort] = useState(csort)
  const cdir = router.query.dir || config.itemdir
  const [dir, set_dir] = useState(cdir)
  const [sortstr,set_sortstr] = useState(`${config.itemsort} ${config.itemdir}`)

  useEffect(() => {
    set_filter(slugfilter)
    set_filterinput(slugfilter)
    if (slugfilter !=='') {
      set_filteropen(true)
    } else {
      set_filteropen(false)
    }
}, [slugfilter])

    useEffect(() => {
      set_sort(csort)
  }, [csort])

  useEffect(() => {
    set_dir(cdir)
  }, [cdir])

  const [likearraydata_active,set_likearraydata_active] = useState([]);

  //POST LIKE DATA
  const { 
    data: likearraydata
  , loading: likearraydataisLoading
  , error: likearraydataisError
  //, boundMutate: itemsMutate
                  } = useSWRWrapper(
                    post_id //id
                    ,`/api/private/post_like/select?id=${post_id}&page=${page}&sort=${sort}&dir=${dir}&cache=${cache}&q=${filter}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

    useEffect(()=>{
        if (!likearraydataisLoading && !likearraydataisError) {
            set_likearraydata_active(likearraydata);
        }
    },[
        likearraydata
        ,likearraydataisLoading
        ,likearraydataisError
    ])

    return <>

                {/* TOP ROW */}
                <div 
                    className="flex w-full flex-col items-center content-center text-left divide-y  divide-gray-700"
                >

                    {likearraydataisLoading && 
                    <div className="p-10">
                         <LoadingProcessing />
                    </div>}

                    {(likearraydataisLoading && likearraydata_active?.length == 0) && 
                    <div className="p-10">
                         <LoadingProcessing />
                    </div>}

                    {!likearraydataisLoading && likearraydata_active?.length == 0 && 
                        <div className="p-10">Nobody likes this yet.</div>
                        }

                    {(likearraydata_active?.length > 0)&&
                    <>
                        
                        {likearraydata_active?.length > 0 &&
                        likearraydata_active?.map((e,index) => {

                            return (
                                <div
                                        key={index}
                                        className="w-full py-4">
                                            <div className="flex items-center content-center w-full px-4 ">
                                                <div className="mr-2 flex-0 font-bold">
                                                    <Link
                                                        href={`/${e.user_name?.toLowerCase()}`}
                                                    >
                                                        <AuthorCredit
                                                            display_data={e}
                                                            isPlaying={false}
                                                            profiledata={profiledata}
                                                        />
                                                    </Link>
                                                </div>
                                                
                                                <div className="flex-1">
                                                    
                                                </div>
                                                <div className="flex-0">
                                                    {/* <ChatTimer
                                                        start_date={+new Date(e?.crdate)}
                                                        timestamp={+new Date(e?.timestamp)}
                                                        time={time}
                                                    /> */}
                                                </div>
                                            </div>
                                        
                                        </div>
                            );
                        })}



                    </>}


                
                </div>


    </>;
}

export default PostLikes