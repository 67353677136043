import { Message } from "@/components/ably/Message";
import { ModalContext } from "@/components/context/modal-wrapper";
import { PlayerContext } from "@/components/player/player-wrapper";
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import { ModalChat } from "@/components/templateux/modal/modal-chat";
import { config, player_style } from "@/lib/config";
import { countUpBySeconds } from "@/lib/utils/countUp"
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import useChatScrollRef from "@/lib/utils/hooks/useChatScrollRef";
import { ifNull } from "@/lib/utils/ifNull";
import { timerFormat } from "@/lib/utils/timerFormat"
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from 'react-input-slider';
import { hasData } from "@/lib/utils/hasData";
import { getUserListenExperience } from "@/lib/utils/getStreamUrl";
import { textProcess } from "@/lib/utils/textprocess";

export default function PostChatReplay({
    file
    ,duration
    ,bol_replay_post
    ,post_id
    ,content_id
    ,content_table
    ,profiledata
    ,userdata
    ,session
    ,owner_id
    ,owner_name
    ,avatar_url
    ,broadcast_title
    ,start_date
    ,router
    ,ssr_data_tribe
}) { 

    const [isMounted,set_isMounted] = useState(false)
    useEffect(()=>{set_isMounted(true)},[])

    
    const {isOpen,set_isOpen} = useContext(ModalContext)

    const {player_state,set_player_state} = useContext(PlayerContext)

    const [chatarraydata_mini, set_chatarraydata_mini] = useState([])

    const [cache,set_cache] = useState('')

    const [ignore_filter, set_ignore_filter] = useState([]) 

    useEffect(()=> {
        let this_ignore_json = userdata?.ignore_json
        if (this_ignore_json?.length > 0) {
          set_ignore_filter(JSON.parse(userdata?.ignore_json))
        }
    }, [userdata])

    // const [active_sentence,set_active_sentence] = useState({seek:0,index:`0-0`})

    //SWR to retrieve all chat messages here
    const { 
        data: chatarraydata
      , loading: chatarraydataisLoading
      , error: chatarraydataisError
      , boundMutate: itemsMutate
                      } = useSWRWrapper(
                        content_id?.toString() //id
                        ,`/api/private/chat/select-broadcast?id=${content_id}&cache=${cache}` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,0  //refreshinterval (0=none)
                        )   
                    
                       

    useEffect(()=>{

        let miniarray = [];

        chatarraydata?.some(function (chat) {

            if (player_state?.seek >= chat.broadcast_duration) {
                //add to the end of the mini array & trim off the previous top of the array if too large.
                miniarray = miniarray?.slice(10 * -1);
                miniarray?.push(chat);
               // set_chatarraydata_mini(prev => [...prev?.slice(config.chat.displaymessages * -1), chat])
               
               set_chatarraydata_mini(miniarray);
               scrollToBottom(scrollRef,'auto',1000);

            }

            //exit loop
            return (player_state?.seek < chat.broadcast_duration);
        })

    },[player_state?.seek])

     //WINDOW RESIZE
     const [offset_height,set_offset_height] = useState(config.chat.replayoffset.sm)
     const [offset_width,set_offset_width] = useState(config.chat.windowoffset.width.sm)
        
     const [windowinnerheight,set_windowinnerheight] = useState(typeof window !== "undefined" ? window.innerHeight-offset_height : 600)
     const [windowinnerwidth,set_windowinnerwidth] = useState(typeof window !== "undefined" ? window.innerWidth-offset_width : 600)
     const [chat_width, set_chat_width] = useState(windowinnerwidth)


     const resize = () => {
        let thisoffset_height = offset_height;
        thisoffset_height = window.innerWidth >= 1024 
                        ? config.chat.replayoffset.lg
                        : config.chat.replayoffset.sm

        thisoffset_height = thisoffset_height - config.chat.replayoffset.offline_extra;

        set_offset_height(thisoffset_height);

        let thisoffset_width = config.chat.windowoffset.width.sm;
        thisoffset_width = window.innerWidth >= 480 
                        ? config.chat.windowoffset.width.lg
                        : config.chat.windowoffset.width.sm

        set_offset_width(thisoffset_width);

        if (typeof window !== "undefined") {
           let newheight = Math.max(window.innerHeight-thisoffset_height,config.chat.minchatheight)
           let newwidth = window.innerWidth-thisoffset_width

           set_windowinnerheight(newheight);
           set_windowinnerwidth(newwidth);
           set_chat_width(newwidth)
        }
    }

    const onResize = useCallback( 
      () => {
        resize();
      }
      , [resize]);
     
     
     useEffect(() => {

            if (typeof window !== "undefined") {
                window.addEventListener("resize", onResize)
                window.addEventListener("orientationchange", onResize)
            }

         return () => {
             window.removeEventListener("resize", onResize)
             window.removeEventListener("orientationchange", onResize)
        }
     }, [])

    useEffect(() => {

            if (typeof window !== "undefined") {
                onResize();
            }

            return () => {};

      }, [offset_height]);
        
     const refscroll = useChatScrollRef(chatarraydata_mini,false);
     const ref = useRef(null);
     const scrollRef = useRef(null);
     const [bottomRef, isOnScreen] = useInView({
        /* Optional options */
        threshold: 0,
        initialInView: true,
        //onChange:(inView)=>{console.log("view changed",inView)}
      });
    const isOnScreenRef = useRef(isOnScreen);
    isOnScreenRef.current = isOnScreen;
    const scrollToBottom = async (ref,behavior,delay) => {


        if (ref?.current) {

          //temporarily hide the scroll button while we scroll to the bottom
  

          await ref?.current?.scrollIntoView(
            {
                behavior: behavior,
                block: 'end',
                inline: 'end'
            })
            


      }


    }
    const startPlayerTimestamp = async (timestamp) => {

       
       
       
        await set_player_state({});

    

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:true
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        ,listen_experience: getUserListenExperience(userdata)
        }

        // console.log("player_object",player_object,display_data)
        

        await set_player_state(player_object);

        //return false;

    }

    const doSeek = (seekto,x) => {
    
        player_state?.playerRef?.current?.seekTo(seekto, 'seconds');
        set_player_state({...player_state
                ,seek:seekto
                ,seek_ratio:x/100
                ,seek_slider:x
                ,isEnded: seekto >= player_state.duration
        });
       
        return 
      }
    
      const doSeekbyRatio = (x) => {
    
        const seekto = Math.round((x/100)*player_state?.duration);
        doSeek(seekto,x);
    
        return 
      }
    
      const doSeekbySeconds = (seconds) => {
    
        const ratio = (seconds * 100) / player_state?.duration;
    
        doSeek(seconds,ratio); 
    
        return 
      }

    
      if (profiledata?.bol_replay_host?.toString() !== "1" || bol_replay_post?.toString() !== "1") {
        return (<> <div className="p-10 text-center">
                                Chat Replay has been disabled by {owner_name}
                        </div></>)
    }

    

    if (chatarraydataisLoading) {
        return (<> <div className="p-10">
                                <LoadingProcessing />
                        </div></>)
    }



    if (!chatarraydataisLoading) {
        return (<>
                    <div className="py-10">
                    <button 
                        className="bg-red-600 text-white px-4 py-2 rounded-md text-lg font-bold"
                        onClick={async ()=> {
                            set_isOpen('replay');
                            if (chatarraydata?.length > 0) {
                                startPlayerTimestamp(ifNull(player_state?.seek,0))
                            } else {
                               const res = await fetch(`/api/private/chat/update-replay?id=${post_id}`, {
                                    method: 'GET',
                                    headers: {
                                      'Content-Type': 'application/json',
                                    },
                                  })
                                  const json = await res.json()
                                  if (json) {
                                    //console.log("json",json)
                                    let cachedate = +new Date()
                                    set_cache(cachedate?.toString());
                                    startPlayerTimestamp(ifNull(player_state?.seek,0))
                                  }
                            }
                        }
                    }
                    >{textProcess(player_state?.isPlaying ? "Open" : "Start")}
                    </button>
                    </div>
                                <ModalChat
                                        isOpen={isOpen=='replay' && isMounted}
                                        ModalContent={<>
                                                    <div className="bg-red-600 h-10 flex items-center content-center px-2 text-lg">
                                                        <div className="flex-0 font-bold cursor-pointer"
                                                        onClick={()=>set_isOpen('')}
                                                        >
                                                            {textProcess(`Chat Replay`)}
                                                        </div>
                                                        <div className="flex-1 text-sm  px-4">
                                                            <Slider
                                                                    axis='x'
                                                                    x={(player_state?.seek_slider)}
                                                                    xmax={100}
                                                                    //disabled={player_state?.isPlaying}
                                                                    onChange={({ x }) => {
                                                                            doSeekbyRatio(x)
                                                                        }}
                                                                    
                                                                    styles={player_style(player_state?.isPlaying)}
                                                                    />
                                                            </div>
                                                        <div>
                                                            <button
                                                            className="text-sm border rounded-sm px-2 py-1"
                                                            onClick={()=>set_isOpen('')}
                                                            >{textProcess(`close`)}</button>
                                                        </div>
                                                    </div>
                                                   <div className="p-4 overflow-y-scroll z-[10000] "
                                                        ref={refscroll}
                                                        style={{
                                                            height: windowinnerheight,
                                                            MozBoxShadow:    `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
                                                            WebkitBoxShadow: `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
                                                            boxShadow:         `inset 0 -20px 20px -20px rgba(0,0,0,0.1)`,
                                                          }}
                                                    >
                                                            {chatarraydata_mini?.length == 0 &&
                                                            <div className="font-normal flex items-top content-start" 
                                                                            
                                                            
                                                    >
                                                        
                                                                <div className="mr-2 text-blue-400 underline hover:no-underline cursor-pointer"
                                                                
                                                                    onClick={()=>{ 
                                                                        startPlayerTimestamp(0)
                                                                    }}
                                                                >
                                                                {timerFormat(countUpBySeconds(Math.round(0)))}
                                                                </div>
                                                                <div 
                                                                    className={`flex-1 text-left `}
                                                                
                                                                >
                                                                    {textProcess(`Starting chat replay...`)}
                                                                </div>
                                                            </div>
                                                            }



                                                            {chatarraydata_mini?.length > 0 &&
                                                            chatarraydata_mini?.map((chat, pindex) => {

                                                                            return (<div className="font-normal flex items-top content-start" 
                                                                            
                                                                                    key={pindex}
                                                                            >
                                                                                
                                                                                        <div className={`${pindex > 0 ? 'border-t border-gray-700' : ''} pr-2 text-blue-400 underline hover:no-underline cursor-pointer  pt-3`}
                                                                                        
                                                                                            onClick={()=>{ 
                                                                                                startPlayerTimestamp(chat.broadcast_duration)
                                                                                            }}
                                                                                        >
                                                                                        {timerFormat(countUpBySeconds(Math.round(chat.broadcast_duration)))}
                                                                                        </div>
                                                                                        <div 
                                                                                            className={`flex-1 text-left `}
                                                                                        
                                                                                        >
                                                                                            {/* {chat.message} */}

                                                                                                <Message
                                                                                                                    key={chat?.chat_id}
                                                                                                                    type={chat?.type}
                                                                                                                    index={pindex}
                                                                                                                    chat={chat}
                                                                                                                    profiledata={profiledata} 
                                                                                                                    userdata={userdata} 
                                                                                                                    isAdmin={false}
                                                                                                                    show_header={(
                                                                                                                                pindex==0 
                                                                                                                                //|| ["2","3"].pindexOf(chat?.emoji?.toString()) > -1 //stickers & uploads
                                                                                                                                || chatarraydata[Math.max(pindex-1,0)]?.int_utc_timestamp <= chat?.int_utc_timestamp - config.chat.messageheaderinterval //time gap on the same chatter
                                                                                                                                || chatarraydata[Math.max(pindex-1,0)]?.id?.toString() !== chat?.id?.toString() //new chatter
                                                                                                                                || chatarraydata[Math.max(pindex-1,0)]?.bol_private?.toString() !== chat?.bol_private?.toString()  //private messages
                                                                                                                                || ["bot","server"].indexOf(chatarraydata[Math.max(pindex-1,0)]?.type?.toString()) > -1
                                                                                                                                )}
                                                                                                                    show_footer={(
                                                                                                                    // pindex==chatarraydata?.length-1 //this is covered by the bottomRef div below
                                                                                                                    // ["2","3"].pindexOf(chatarraydata[Math.min(pindex+1,chatarraydata?.length-1)]?.emoji?.toString()) > -1 //stickers & uploads
                                                                                                                        chatarraydata[Math.min(pindex+1,chatarraydata?.length-1)]?.int_utc_timestamp - config.chat.messageheaderinterval > chat?.int_utc_timestamp  //time gap on the same chatter    
                                                                                                                    || chatarraydata[Math.min(pindex+1,chatarraydata?.length-1)]?.id?.toString() !== chat?.id?.toString()  //new chatter
                                                                                                                    || chatarraydata[Math.min(pindex+1,chatarraydata?.length-1)]?.bol_private?.toString() !== chat?.bol_private?.toString() //private messages
                                                                                                                    || ["bot","server"].indexOf(chatarraydata[Math.min(pindex+1,chatarraydata?.length-1)]?.type?.toString()) > -1
                                                                                                                    )}
                                                                                                                    openModal={()=>{}}
                                                                                                                    openModalChooser={()=>{}}
                                                                                                                    closeModal={()=>{}}
                                                                                                                    CloseButton={()=>{}}
                                                                                                                    msg={chat}
                                                                                                                    set_msg={()=>{}}
                                                                                                                    session={session}
                                                                                                                    chat_emoji={profiledata?.chat_emoji}
                                                                                                                    inputRef={null}
                                                                                                                    position={pindex>=chatarraydata?.length-5 ? "left bottom" : "left top"}
                                                                                                                    time={null}
                                                                                                                    chatWidth={chat_width} //{chat_width}
                                                                                                                    set_commenting={()=>{}}
                                                                                                                    server_website={ssr_data_tribe?.server_website}
                                                                                                                />

                                                                                        </div>

                                                                            </div>)
                                                                
                                                            })}
                                                            
                                                            <div
                                                                ref={bottomRef} 
                                                                className={` h-3 block 
                                                                    
                                                                        `}><></></div>
                                                                        
                                                            <div
                                                                ref={scrollRef} 
                                                                className={`h-[1px] block 
                                                                    
                                                                            `}><></></div>

                                                    </div>
                                            
                                        </>}
                                        />
                   </>)
    }
    
}