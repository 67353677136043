//post-download.tsx

import * as React from "react";
import { useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import isAdminUser from "@/lib/utils/isAdminUser";
import WizardSectionForm from "@/components/templateux/wizard/wizard-section-form";
import { formatBytes } from "@/lib/utils/formatBytes";
import { countUpBySeconds } from "@/lib/utils/countUp";
import { timerFormat } from "@/lib/utils/timerFormat";
import { tablehelptype } from "@/lib/config";

type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  processing?: number;
  set_processing?: any;
  set_changes?: any;
  changes?: any;
  max_files?: any;

  //post info
  post_id?: any;
  itemdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?:any;
  postcache?:any;
  set_postcache?:any;
  typevalue?:any;
  set_typevalue?:any;

  //inherited info
  ownerdata?: any;
  userdata?: any;

  navtype?: string;
  helptype?: string;
}

function PostDownload({
  //submitting
   submitHandler
  ,submitting
  ,set_submitting
  ,processing
  ,set_processing
  ,set_changes
  ,changes
  ,max_files

  //post info
  ,post_id
  ,itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,show_graphic
  ,form_reset
  ,set_form_reset
  ,actionText
  ,postcache
  ,set_postcache
  ,typevalue
  ,set_typevalue

  //inherited info
  ,ownerdata
  ,userdata
  ,navtype
  ,helptype
}: Props) {

  const [isDownloading,set_isDownloading] = useState(false)

  const [ispageloaded,set_ispageloaded] = useState(false)
  useEffect(()=>{set_ispageloaded(true)},[])
 
  const [admin_user_name,set_admin_user_name] = useState((!itemdataisLoading && !itemdataisError && itemdata && hasData(itemdata?.user_name)) 
                                                          ? itemdata?.user_name 
                                                          : ownerdata?.user_name)
  const [bol_admin_user_name,set_bol_admin_user_name] = useState("0")
  const [user_id,set_user_id] = useState(userdata?.id)


 
  
  const [_post_type, set_post_type] = useState(hasData(itemdata?.post_type) ? itemdata?.post_type : typevalue)
  const [_post_title, set_post_title] = useState(hasData(itemdata?.post_title) ? itemdata?.post_title : '')
  const [_post_body, set_post_body] = useState(hasData(itemdata?.post_body) ? itemdata?.post_body : '')

  const [_post_contents_json, set_post_contents_json] = useState(hasData(itemdata?.post_contents_json) ? itemdata?.post_contents_json : '[]')
  
  
  useEffect(()=>{


    if (form_reset==1 
      && ((
          hasData(post_id) 
          && post_id.toString() !== "0" 
          && !itemdataisLoading && !itemdataisError
          )
        )
      ) {

      set_admin_user_name(hasData(itemdata?.user_name) 
                          ? itemdata?.user_name 
                          : userdata?.user_name)

        set_post_type(hasData(itemdata?.post_type)
                                    ? itemdata?.post_type 
                                    : typevalue
                                  )

        set_post_title(hasData(itemdata?.post_title)
                                    ? itemdata?.post_title 
                                    : ''
                                  )
        set_post_body(hasData(itemdata?.post_body) 
                                    ? itemdata?.post_body 
                                    : ''
                                    )
       


        set_post_contents_json(hasData(itemdata?.post_contents_json) 
                                  ? itemdata?.post_contents_json 
                                  : '[]')

        
        set_form_reset(0) //make sure we dont remove the data again now that it's filled in.
    
    }
 
},[
   itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,post_id
  ,changes
  ,form_reset
  ,set_form_reset
  ,typevalue
  ,userdata
])



  const [wizard_id,set_wizard_id] = useState(0);
  const wizard_array = [
    {
      title:'Post content'
      ,description:''
      ,short: 'content'
      ,fields: []
    }
  ]



          



    const [isAdmin,set_isAdmin] = useState(isAdminUser(
       ownerdata?.id?.toString()          //user_id
      ,ownerdata?.bol_staff_help    //bol_staff_help
      ,ownerdata?.staff_json        //staff_json
      ,ownerdata?.bol_admin_help    //bol_admin_help
      ,userdata?.id?.toString()                            //my_id
      ,userdata?.bol_admin?.toString()              //global_admin
      ,tablehelptype('post')
  ))
   
  const onDownload = async (url,name) => {

    await set_isDownloading(true);
    // using Java Script method to get PDF file
    await fetch(url).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        }).then(async ()=> await set_isDownloading(false)
        )
    })
}
 

  return (
    <>
    {/* info COMPONENT */}
    <div className="w-full">



      {/* FORM */}
      <div>

        <WizardSectionForm
          section_id={0}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        ><>


          {JSON.parse(_post_contents_json)?.length > 0 &&
           JSON.parse(_post_contents_json)?.map((e,index)=> {


            return (<div 
                        key={index}
                    >

                      {/* {MOBILE} */}
                      <div className="block sm:hidden w-full">
                        <div className="flex items-start content-top">
                          <div className="flex-1 text-xs">
                              <div className="font-bold text-base">{e?.filename}</div>
                              <div>{formatBytes(e?.size,2)}</div>
                              <div>{timerFormat(countUpBySeconds(Math.round(e?.duration)))}</div>
                          </div>
                          <div className="flex-0">
                            <div
                              className="w-32 text-center cursor-pointer px-4 py-2 bg-blue-500 text-white hover:opacity-80 hover:shadow-md rounded-md"
                              role="button"
                              onClick={async () => await onDownload(e?.url,e?.filename)}
                            >
                              Download
                            </div>
                          </div>

                        </div>
                      </div>
                        
                      {/* {DESKTOP} */}
                      <div className="hidden sm:block w-full">
                        {/* <div>{JSON.stringify(e)}</div> */}
                        <table className="table-auto w-full border-separate border-spacing-2">
                          <thead>
                            <tr>
                              <th>Filename</th>
                              <th>Size</th>
                              <th>Duration</th>
                              <th className="text-right"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="hover:bg-[rgb(20,27,42)] ">
                              <td>{e?.filename || `Untitled`}</td>
                              <td>{formatBytes(e?.size,2)}</td>
                              <td>{timerFormat(countUpBySeconds(Math.round(e?.duration)))}</td>
                              <td className="flex justify-end">
                                {isDownloading &&
                                <div
                                className="px-4 py-2 bg-grey-500 text-white rounded-md cursor-not-allowed"
                                
                              >
                                Downloading...
                              </div>
                                }
                                {!isDownloading &&
                                <button
                                  className="w-32 text-center cursor-pointer px-4 py-2 bg-blue-500 text-white hover:opacity-80 hover:shadow-md rounded-md whitespace-nowrap"
                                  role="button"
                                  onClick={async () => await onDownload(e?.url,e?.filename)}
                                  tabIndex={0}
                                >
                                  Download File
                                </button>
                                }
                              </td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>

                    </div>)
           
            // return (<PostFormType
            //           key={index}
            //           _post_type={_post_type}
            //           set_post_type={set_post_type}
            //           _post_contents_json={_post_contents_json}
            //           set_post_contents_json={set_post_contents_json}
            //           _post_body={_post_body}
            //           set_post_body={set_post_body}
            //           submitting={submitting}
            //           set_submitting={set_submitting}
            //           processing={processing}
            //           set_processing={set_processing}
            //           changes={changes}
            //           set_changes={set_changes}
            //           index={index}
            //           max_files={max_files}
            //           isEditable={false}
            //         />)
            

           }
          
          )}


        </></WizardSectionForm> 


    </div>
    {/* SET referral FORM */}

    </div>
    {/* // referral SET COMPONENT */}
  </>
  )
}

export default PostDownload



