

import { useSession } from "next-auth/client"
import Link from "next/link"
import * as React from "react"
import { slugify } from "@/lib/utils/slugify"
import {  useContext, useEffect, useState } from "react"
import { hasData } from "@/lib/utils/hasData"
import _ from 'underscore';
import { bumber } from "@/lib/utils/bumber"
import toast, { toastConfig } from 'react-simple-toasts';
import { PlayerContext } from "@/components/player/player-wrapper"
import isAdminUser from "@/lib/utils/isAdminUser"
import BroadcastSingle from "../broadcast/broadcast-single"
import PostMenuOptions from "./post-menu-options"
import { friendlydate } from "@/lib/utils/friendlydate"
import { cleanGuestJson } from "@/lib/utils/cleanGuestJson"
import { extractJson } from "@/lib/utils/extractJson"
import { getTimestamp } from "@/lib/utils/getTimestamp"
import { getNumber } from "@/lib/utils/hasNumber"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"

export default function PostSingleAudioOnly({ 
              router
              ,itemdata
              ,userdata
              ,ownerdata
              ,display_view
              ,openModal
              ,closeModal
              ,cache
              ,set_cache
              ,navtype
              ,helptype
              ,tabs
              ,set_tabs
              ,slug
              ,deleteHandler
              ,stickyHandler
              ,transcribeHandler
              ,isDraft
              ,set_isDraft
              ,isAdmin
              ,isAuthor
              ,clip_state
              ,set_clip_state
             }) {


               
    const [session,loading] = useSession()

    const [liked,set_liked] = useState(false)
    const [likes,set_likes] = useState(itemdata?.likes)
    const [comments,set_comments] = useState(itemdata?.comments)
    const [msg,set_msg] = useState('')

    const [timestamp,set_timestamp] = useState(getNumber(router.query?.t))
    const {player_state,set_player_state} = useContext(PlayerContext)
    const [post_date,set_post_date] = useState(new Date());
    const [display_data,set_display_data] = useState(  itemdata  )
    const [permaurl,set_permaurl] = useState(`/${itemdata?.user_name?.toLowerCase()}/posts`)
    const [toggle,set_toggle] = useState(itemdata?.bol_public)
    const [broadcast_object,set_broadcast_object] = useState({
                                                                url:'',
                                                                hash:'',
                                                                size:'',
                                                                source:'',
                                                                category:'',
                                                                duration:'',
                                                                end_date:'',
                                                                extention:'',
                                                                post_type:'',
                                                                thumbnail:'',
                                                                start_date:'',
                                                                metadata_json:'',
                                                                user_id:0,
                                                                user_name:'',
                                                                timestamp:'',
                                                                avatar_url:'',
                                                                content_id:0,
                                                                content_table:'post',
                                                                guests_json:'[]'
                                                              })



    
    useEffect(() => {
        

        set_display_data(itemdata);
        set_likes(itemdata?.likes);
        set_liked(hasData(itemdata?.liked) ? true : false);
        set_post_date(new Date(itemdata?.publish_date))
        set_permaurl(`/${itemdata?.user_name?.toLowerCase()}/posts/${slugify(itemdata?.post_id,itemdata?.post_title)}`)
        set_toggle(itemdata?.bol_public)
        if (itemdata?.content_table=="broadcast") {
          if (hasData(itemdata?.broadcast_contents_json)) {

           // console.log("hasData(itemdata?.broadcast_contents_json)",cleanGuestJson(itemdata?.broadcast_contents_json))
           
            set_broadcast_object(JSON.parse(cleanGuestJson(itemdata?.broadcast_contents_json))[0])
          }
        } else {
          if (hasData(itemdata?.post_contents_json)) {
           // console.log("hasData(itemdata?.post_contents_json)",cleanGuestJson(itemdata?.post_contents_json))
            set_broadcast_object(JSON.parse(cleanGuestJson(itemdata?.post_contents_json))[0])
          }
        }
          
          
      
    },[
       itemdata
    ])

  // const [isAdmin,set_isAdmin] = useState(false)
     
  // useEffect(() => {


  //     if (itemdata && userdata) {

  //         set_isAdmin(isAdminUser(
  //                 itemdata?.user_id?.toString()          //user_id
  //                 ,itemdata?.bol_staff_help    //bol_staff_help
  //                 ,itemdata?.staff_json        //staff_json
  //                 ,itemdata?.bol_admin_help    //bol_admin_help
  //                 ,userdata?.id?.toString()                            //my_id
  //                 ,userdata?.bol_admin?.toString()              //global_admin
  //                 ,'post'
  //         ))

  //     }

  //   }, [itemdata
  //     , userdata
  // ]);

  // const [isDraft,set_isDraft] = useState((display_data?.user_id?.toString() == userdata?.id?.toString() && display_data?.bol_public?.toString() == "0"))
  useEffect(() => {
    set_isDraft((display_data?.user_id?.toString() == userdata?.id?.toString() && display_data?.bol_public?.toString() == "0"))
  },[
     display_data
    ,userdata
    ,set_isDraft
  ])

    const togglePublish = async (id) => {
      let vtoggle = bumber(toggle);
      set_toggle(vtoggle);

        toastConfig({
          time: 5000,
          className: '',
          position: 'right'
      });

      if (hasData(id)) {
        const res = await fetch(`/api/private/post/update-public?id=${id}&action=${vtoggle}`);;
        const json = await res.json()
        if (json) {

           //console.log(json)
          const { action } = json[0][0]
          //console.log(action)
          if (action==1) { 
            toast(`Post published`, { time: 3000, className: '', clickable: true, clickClosable: false });
            //mutateItemsDeleteOne(id)
            set_isDraft(false)
          } else {
            toast(`Post unpublished`, { time: 3000, className: '', clickable: true, clickClosable: false });
            //mutateItemsDeleteOne(id)
            set_isDraft(true)
          }
        }
      }
    }

    function addSeconds(t,seconds) {
      var d = new Date(t);
      return new Date(d.getTime() + (seconds * 10000));
    }

     {/* TITLE */}
    const title=(<>        
                  {hasData(display_data?.post_title) && ["5"].indexOf(display_data?.post_type?.toString()) == -1 &&
                  <div className="text-left p-2">
                    <h2 className={`text-2xl font-bold`}>
                    <Link href={permaurl}>

                      {display_data?.post_title}

                    </Link>
                    </h2>
                  </div>
                  }</>)
    
    {/* POST BODY */}
    const description = (<>
                  {hasData(display_data?.post_body) && 
                  <div className='text-left p-2'>
                    {display_data?.post_body} 
                  </div>
                  }</>)

        const prepPlayerTimestamp = async (
                                      timestamp, 
                                      file, 
                                      duration, 
                                      post_id,
                                      post_type,
                                      content_id, 
                                      content_table, 
                                      owner_id, 
                                      owner_name, 
                                      avatar_url, 
                                      start_date, 
                                      broadcast_title
                                      ) => {

                                        

        await set_player_state({});

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,post_type: post_type
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:false 
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        }

        // console.log("player_object",player_object,display_data)

        await set_player_state(player_object);

    }

    const parseTimestamp = (body,index) => {
      var inputString = body;
      const regex_id = /(([0-9]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]/gm;
      inputString = inputString.replace(regex_id, (match, group1) => {
        return `<a data-timestamp="1" data-timestampindex="${index}" class="cursor-pointer text-blue-500 underline hover:text-white">${match}</a>`;
      });
      return inputString;
    }

    const audioObject = (e,post_type) => {

      if (post_type?.toString() == "5") {
        return <BroadcastSingle
                  itemdata={{ 
                              content_id:display_data?.post_id,
                              content_table:'post',
                              broadcast_url:e?.url?.replace(`${e?.hash}.${e?.extention}`,''),
                              broadcast_title:display_data?.post_title,
                              hash:broadcast_object?.hash,
                              recording_hash:broadcast_object?.hash,
                              size:broadcast_object?.size,
                              source:broadcast_object?.source,
                              broadcast_category:broadcast_object?.category,
                              duration:e?.duration,
                              extention:broadcast_object?.extention,
                              post_type:broadcast_object?.post_type,
                              broadcast_thumbnail:broadcast_object?.thumbnail,
                              start_date:display_data?.publish_date, //isodate(display_data?.publish_date),
                              end_date:display_data?.publish_date, //isodate(addSeconds(display_data?.publish_date,e?.duration)),
                              metadata_json:broadcast_object?.metadata_json,
                              timestamp:display_data?.timestamp,
                              user_id:display_data?.user_id,
                              user_name:display_data?.user_name,
                              avatar_url:getAvatarUrl(
                                display_data?.avatar_url
                                ,display_data?.avatar_url_sfw
                                ,display_data?.bol_nsfw
                                ,display_data?.avatar_crdate
                                ,''
                                ,display_data?.user_id
                              ),

                              broadcast_description:display_data?.post_description,
                              broadcast_tags: "[]",
                              bol_recording: 1,
                              bol_public: 1,
                              bol_status: 2,
                              guests_json: display_data?.guests_json,
                              broadcast_hash: broadcast_object?.hash,
                              crdate: display_data?.publish_date, //isodate(display_data?.publish_date),
                              schedule_start: '',
                              schedule_end: '',
                              staff_json: "[]",
                              bol_admin_help: 1,
                              bol_staff_help: 1,
                              bolrealdata: 1,
                              owner: 0,
                            }}
                  hostsjsondata={[]}
                  hostsjsondataisLoading={false}
                  hostsjsondataisError={false}
                  placeholderdata={itemdata}
                  userdata={userdata}
                  owner_id={1}
                  owner_name={'gross'}
                  placeholder={false}
                  display_view={"post"}
                  openModal={openModal}
                  closeModal={closeModal}
                  cache={cache}
                  set_cache={set_cache}
                  navtype={navtype}
                  helptype={helptype}
                  timestamp={timestamp}
                  post_override={true}
                  permaurl={permaurl}
                  post_id={display_data?.post_id}

              />
      } else { //2,3,13
        return  <div
                    className="p-2"
                  >
                    

                    <BroadcastSingle
                      itemdata={{ 
                                  content_id:display_data?.post_id,
                                  content_table:'post',
                                  broadcast_url:e?.url?.replace(`${e?.hash}.${e?.extention}`,''),
                                  broadcast_title:display_data?.post_title,
                                  hash:broadcast_object?.hash,
                                  recording_hash:broadcast_object?.hash,
                                  size:broadcast_object?.size,
                                  source:broadcast_object?.source,
                                  broadcast_category:broadcast_object?.category,
                                  duration:e?.duration,
                                  extention:broadcast_object?.extention,
                                  post_type:broadcast_object?.post_type,
                                  broadcast_thumbnail:broadcast_object?.thumbnail,
                                  start_date:display_data?.publish_date, //isodate(display_data?.publish_date),
                                  end_date:display_data?.publish_date, //isodate(addSeconds(display_data?.publish_date,e?.duration)),
                                  metadata_json:broadcast_object?.metadata_json,
                                  timestamp:display_data?.timestamp,
                                  user_id:display_data?.user_id,
                                  user_name:display_data?.user_name,
                                  avatar_url:getAvatarUrl(
                                    display_data?.avatar_url
                                    ,display_data?.avatar_url_sfw
                                    ,display_data?.bol_nsfw
                                    ,display_data?.avatar_crdate
                                    ,''
                                    ,display_data?.user_id
                                  ),

                                  broadcast_description:display_data?.post_description,
                                  broadcast_tags: "[]",
                                  bol_recording: 1,
                                  bol_public: 1,
                                  bol_status: 2,
                                  guests_json: display_data?.guests_json,
                                  broadcast_hash: broadcast_object?.hash,
                                  crdate: display_data?.publish_date, //isodate(display_data?.publish_date),
                                  schedule_start: '',
                                  schedule_end: '',
                                  staff_json: "[]",
                                  bol_admin_help: 1,
                                  bol_staff_help: 1,
                                  bolrealdata: 1,
                                  owner: 0,
                                }}
                      hostsjsondata={[]}
                      hostsjsondataisLoading={false}
                      hostsjsondataisError={false}
                      placeholderdata={itemdata}
                      userdata={userdata}
                      owner_id={1}
                      owner_name={'gross'}
                      placeholder={false}
                      display_view={"post"}
                      openModal={openModal}
                      closeModal={closeModal}
                      cache={cache}
                      set_cache={set_cache}
                      navtype={navtype}
                      helptype={helptype}
                      timestamp={timestamp}
                      post_override={true}
                      permaurl={permaurl}
                      post_id={display_data?.post_id}
              
                  />
                </div>
      } 

    }

    if (["2","3","5","13"].indexOf(display_data?.post_type?.toString()) == -1) {
      return (<></>)
    } 


    return (<>
    <div className="px-2 w-full  inline-block bg-gray-900 ">
      <div className={` my-1  rounded-md   bg-gray-800 shadow-md rounded-t-md
      ${isDraft
        ? 'border-2 border-dotted  border-red-700'
        : 'border  border-gray-700'
        }
      `}>

        <div className="flex-1 flex items-center content-center">

          {/* MAIN CONTENT */}
          <div className="flex-1">

            {/* SHOWREELS */}
            {["5"].indexOf(display_data?.post_type?.toString()) > -1 && 
            <div className='flex justify-center flex-col p-2'>
              {hasData(display_data?.post_contents_json) && 
                  JSON.parse(display_data?.post_contents_json).filter((e)=>hasData(e?.url)).map((e,index)=> {

                  

                    return (<div key={index}>
                            {audioObject(e,display_data?.post_type)}
  
                              </div>
                    )
                  }
                )
              }
            </div>
            }
            
            {/* FILES */}
            {["2","3","13"].indexOf(display_data?.post_type?.toString()) > -1 && 
            <div>
              <div className='flex justify-center flex-col'>
                {hasData(display_data?.post_contents_json) && 
                  JSON.parse(display_data?.post_contents_json).filter((e)=>hasData(e?.url)).map((e,index)=> {
                   return (<div key={index}
                            className=" flex justify-center flex-col"
                              
                            >
                              {audioObject(e,display_data?.post_type)}
                              
                            </div>)
                  }
                
                  )
                } 
              </div>
            </div>
            }

            
            {/* TITLE & DESCRIPTION FOR AUDIO AND VOICE MEMOS */}
            {/* {["2","3"].indexOf(display_data?.post_type?.toString()) > -1 &&
            <>
              {description}
            </> 
            } */}



          </div>

          <div className="flex-0 flex ">
            <PostMenuOptions 
              item_id={display_data?.post_id}
              item_name={display_data?.post_title}
              userdata={userdata}
              owner_id={display_data?.user_id}
              timestamp={display_data?.timestamp}
              session={session}
              openModal={openModal}
              closeModal={closeModal}
              cache={cache}
              set_cache={set_cache}
              permaurl={permaurl}
              isAdmin={isAdmin}
              isContributor={display_data?.contributor_level >= 1}
              post_date={friendlydate(post_date)}
              post_type={display_data?.post_type}
              deleteHandler={deleteHandler}
              stickyHandler={stickyHandler}
              transcribeHandler={transcribeHandler}
              bol_replay_host={display_data?.bol_replay_host}
              bol_transcribe={display_data?.bol_transcribe}
              transcribe_status={display_data?.transcribe_status}
              bol_sticky={display_data?.bol_sticky}
              
              player_state={player_state}
              set_player_state={set_player_state}
              file={extractJson(display_data?.post_contents_json,"url")}
              duration={extractJson(display_data?.post_contents_json,"duration")}
              content_id={display_data?.content_id}
              content_table={display_data?.content_table}
              owner_name={display_data?.user_name}
              avatar_url={getAvatarUrl(
                display_data?.avatar_url
                ,display_data?.avatar_url_sfw
                ,display_data?.bol_nsfw
                ,display_data?.avatar_crdate
                ,''
                ,display_data?.user_id
              )}
              start_date={extractJson(display_data?.post_contents_json,"start")}
              stream_status={hasData(extractJson(display_data?.post_contents_json,"end")) ? "0" : "1"}
              clip_state={clip_state}
              set_clip_state={set_clip_state}
            />
          </div>

        </div>

        {/* DRAFT BAR */}
        {isDraft && 
          <div className="flex justify-center border-t  border-gray-700 py-2">
            
            <div className="flex-0 text-sm cursor-pointer opacity-100 hover:opacity-75 bg-green-600 text-white hover:bg-green-500 px-4 py-2 rounded-full"
              onClick={()=> togglePublish(display_data?.post_id)}
            >
              Publish this Draft?
            </div>
          </div>
          }


      </div>


     
    </div>

    </>)

   
}

  