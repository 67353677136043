

import { signIn, useSession } from "next-auth/client"
import Link from "next/link"
import * as React from "react"
import PostMenuOptions from "./post-menu-options"
import { slugify } from "@/lib/utils/slugify"
import {  useContext, useEffect, useRef, useState } from "react"
import { hasData } from "@/lib/utils/hasData"
import _ from 'underscore';
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { bumber } from "@/lib/utils/bumber"
import toast, { toastConfig } from 'react-simple-toasts';
import AuthorCredit from "@/components/templateux/user/author-credit"
import { friendlydate } from "@/lib/utils/friendlydate"
import BroadcastSingle from "../broadcast/broadcast-single"
import { formatPlural } from "@/lib/utils/formatPlural"
import SvgJsxNew from "@/components/templateux/svg/svg-jsx-new"
import { getCacheDate } from "@/lib/utils/getCacheDate"
import { lookupPost, title_format } from "@/lib/config"
import Image from 'next/legacy/image';
import TextareaAutosize from 'react-textarea-autosize'
import parsehtml from "@/lib/utils/parsehtml"
import parseMarkDown from "@/lib/utils/parseMarkDown"

import { cleanGuestJson } from "@/lib/utils/cleanGuestJson"
import { useConfirm } from "@/lib/utils/hooks/useConfirm"
import markdownStyles from './markdown-styles.module.css'
import { formatDate } from "@/lib/utils/formatDate"
import { PlayerContext } from "@/components/player/player-wrapper"

import { getTimestamp } from "@/lib/utils/getTimestamp";
import { getNumber } from "@/lib/utils/hasNumber"
import { extractJson } from "@/lib/utils/extractJson"
import { TribeContext } from "@/components/context/tribe-wrapper"
import ReactPlayer from "react-player/lazy"
import { updateTitle } from "@/lib/utils/updateTitle"
import { getAvatarUrl } from "@/lib/utils/getAvatarUrl"
import ProfileFollow from "../item/profile-follow"
import { followHandler, notifyHandler } from "@/lib/utils/handlers/profile"
import { textProcess } from "@/lib/utils/textprocess"

export default function PostSingle({ 
              router
              ,itemdata
              ,userdata
              ,ownerdata
              ,display_view
              ,openModal
              ,closeModal
              ,cache
              ,set_cache
              ,navtype
              ,helptype
              ,tabs
              ,set_tabs
              ,slug
              ,deleteHandler
              ,stickyHandler
              ,transcribeHandler
              ,isDraft
              ,set_isDraft
              ,isAdmin
              ,isAuthor
              ,likes
              ,set_likes
              ,comments
              ,set_comments
              ,opens
              ,set_opens
              ,descriptionRef
              ,index
              ,clip_state
              ,set_clip_state
              ,isOpen
              ,isExpanded
              ,isFlat
              ,metaScroll
             }) {

              const [isMounted,set_isMounted] = useState(false)
              useEffect(()=>{set_isMounted(true)},[])

              const { isConfirmed } = useConfirm();

              const ssr_data_tribe = useContext(TribeContext)

              
              const {player_state,set_player_state} = useContext(PlayerContext)
              const [broadcast_object,set_broadcast_object] = useState({
                url:'',
                hash:'',
                size:'',
                source:'',
                category:'',
                duration:'',
                end_date:'',
                extention:'',
                post_type:'',
                thumbnail:'',
                start_date:'',
                metadata_json:'',
                user_id:0,
                user_name:'',
                timestamp:'',
                avatar_url:'',
                content_id:0,
                content_table:'post',
                guests_json:'[]'
              })

                  //followers
              const [state_following_them,set_state_following_them] = useState(false)
              const [state_following_them_status,set_state_following_them_status] = useState(0)
              
              
              //const [containerheight, set_containerheight] = useState(0)
              const containerRef = useRef(null);
              const [original_height, set_original_height] = useState(0)

              


              const [toggle,set_toggle] = useState(itemdata?.bol_public)
              const [display_data,set_display_data] = useState(itemdata)
              const [post_date,set_post_date] = useState(new Date());
              const [permaurl,set_permaurl] = useState(`/${itemdata?.user_name?.toLowerCase()}/posts`)
           

              const [show_comments,set_show_comments] = useState((itemdata?.bol_comments?.toString() !== "1" && ownerdata?.bol_post_comments?.toString() !== "0"))

              const [_bol_notify_options,set_bol_notify_options] = useState(itemdata?.bol_notify_options || 1)
              const [_notify_options_bitarray,set_notify_options_bitarray] = useState(itemdata?.notify_options_bitarray || 32767)
              const [_notify_show_array,set_notify_show_array] = useState(itemdata?.notify_show_array || [])

              useEffect(() => {
                set_display_data(itemdata);
                set_likes(itemdata?.likes);
                set_opens(itemdata?.opens);
                set_post_date(new Date(itemdata?.publish_date))
                set_permaurl(`/${itemdata?.user_name?.toLowerCase()}/posts/${slugify(itemdata?.post_id,itemdata?.post_title)}`)
                set_toggle(itemdata?.bol_public)

                set_bol_notify_options(itemdata?.bol_notify_options || 1);
                set_notify_options_bitarray(itemdata?.notify_options_bitarray || 32767);
                set_notify_show_array(itemdata?.notify_show_array || []);

                if (hasData(itemdata?.post_contents_json)) {
                  // console.log("itemdata?.post_contents_json",cleanGuestJson(itemdata?.post_contents_json))
                   set_broadcast_object(JSON.parse(cleanGuestJson(itemdata?.post_contents_json))[0])
                 } else {
                  if (hasData(itemdata?.broadcast_contents_json)) {
                    //console.log("itemdata?.broadcast_contents_json",cleanGuestJson(itemdata?.broadcast_contents_json))
                     set_broadcast_object(JSON.parse(cleanGuestJson(itemdata?.broadcast_contents_json))[0])
                   }
                 }
            
                
                set_state_following_them(hasData(itemdata?.following_them) ? parseInt(itemdata?.following_them) > 0 : false);
                set_state_following_them_status(hasData(itemdata?.following_them_status) ? parseInt(itemdata?.following_them_status) : 1);
            },[
               itemdata
            ])
            
              useEffect(() => {
       

                set_show_comments((itemdata?.bol_comments?.toString() == "1" && ownerdata?.bol_post_comments?.toString() !== "0"))
        
            },[
               itemdata
               ,ownerdata
            ])

            
    // useEffect(() => {



    //   if (!containerRef?.current) return;

    //     if (original_height !== 204) set_original_height(containerRef?.current?.clientHeight);
        
    //     if (containerRef?.current?.clientHeight >= 204 ) {
    //       set_expanded(opened)
    //     }

    // },[]);
  
    




    // useEffect(() => {
    //   if (opened) {
    //     set_expanded(true)
    //   }
    // }, [
    //   opened
    // ]);

    // useEffect(()=> {
    //   if (expand) {
    //     set_expanded(true);
    //     openHandler(display_data?.post_id)
    //     set_opens(prev=>prev+1)
    //   }
    // },[expand])
    

    const [session,loading] = useSession()
    const [msg,set_msg] = useState('')
    const msgRef = useRef(null)
    const [commenting,set_commenting] = useState(false)
    const [timestamp,set_timestamp] = useState(getNumber(router.query?.t))

    useEffect(()=>{
      if (hasData(router.query?.t)) {
        set_timestamp(getNumber(router.query?.t));
      }

      if (hasData(router.query?.t) 
          && hasData(broadcast_object?.url)
          && hasData(display_data?.post_type)
          ) {

        //set the time stamp
        prepPlayerTimestamp(
          parseInt(router.query?.t),
          broadcast_object?.url, 
          broadcast_object?.duration,
          display_data?.post_id, 
          display_data?.post_type,
          display_data?.post_type?.toString()=="2"
            ? display_data?.post_id
            : display_data?.content_id, 
          display_data?.post_type?.toString()=="2"
            ? "post"
            : display_data?.content_table, 
          display_data?.user_id, 
          display_data?.user_name, 
          getAvatarUrl(
            display_data?.avatar_url
            ,display_data?.avatar_url_sfw
            ,display_data?.bol_nsfw
            ,display_data?.avatar_crdate
            ,''
            ,display_data?.user_id
          ),
          broadcast_object?.start_date, 
          display_data?.post_title
        );
      }
    },[
      router.query?.t,
      broadcast_object?.url,
      display_data?.content_id
    ])

  
    const doEdit = () => {
      //todo make the edit stuff happen here so we can pass this into postmenuoptions
    }

    //LIKES
    const likeHandler = async (post_id,poster_id,my_id) => {

    
      if (hasData(post_id)) {
        const res = await fetch(`/api/private/post_like/insert?id=${post_id}&type=1`);;
        const json = await res.json()
        if (json) {

          const { action, likes } = json[0][0]

          //UPDATE FROM DB WITH ACTUAL VALUES
          set_likes(likes);

          if (action?.toString()=="-1") {
            toast(`Post unliked`, { time: 3000, className: '', clickable: true, clickClosable: false });
          } else if (["1","2"].indexOf(action?.toString()) > -1) { 
            toast(`Post liked!`, { time: 3000, className: '', clickable: true, clickClosable: false });
          }

          //set_cache(getCacheDate());

          return action
        }
      }

      return -3
    
    }

      //OPENS
      const openHandler = async (post_id,post_type) => {

        openModal("post",post_id,post_type);

        //UPDATE FROM DB WITH ACTUAL VALUES
        set_opens(prev=>prev+1);
        // set_expanded(true);

        if (hasData(post_id)) {
          const res = await fetch(`/api/private/post_open/insert?id=${post_id}&type=1`);;
          const json = await res.json()
          if (json) {
  
            const { action } = json[0][0]
  
            //set_cache(getCacheDate());
  
            return action
          }
        }
  
        return -3
      
      }

    //COMMENTS
const commentHandler = async (
                            e,
                            msg,
                            post_id,
                            permaurl,
                            set_comments
                            ) => {
         
        e.preventDefault()

       
        if (loading) {
            
            toast('Chat authorization is still loading. This might be because you are on a slow connection. Hang tight a moment...', { time: 3000, className: '', clickable: true, clickClosable: false });
            return false
        }

        if (!loading && !session) {
            
            toast('Please login to an account to participate.', { time: 3000, className: '', clickable: true, clickClosable: false });
            return false
        }


        if (msg?.length === 0) {
            
            toast('Nothing to submit.', { time: 3000, className: '', clickable: true, clickClosable: false });
            return false
        }


        fetch(`/api/private/post_comment/insert?id=${post_id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              msg: msg
          }),
        })
        .then((res) => res.json())
        .then((json) => {
            if (json) {
                //console.log("json",json)

                const { outcome, comments:updated_comments } = json[0][0]

                
                if (["1"].indexOf(outcome?.toString()) > -1) { 
                  //toast(`Post commentd!`, { time: 3000, className: '', clickable: true, clickClosable: false });
                  set_msg('')
                  set_commenting(false);
                  set_cache(getCacheDate())
                  set_tabs('response')
                  set_comments(updated_comments);
                  if (!hasData(slug)) toast(`Comment posted! Click here to see it`, { time: 7500, className: '', clickable: true, clickClosable: false, onClick: (e=>{router.push(permaurl)}) });

                } else {
                    console.log("error on chat submit",json,outcome,post_id,msg)
                    if (outcome == -1) toast(`Comments have been disabled on this post.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                    if (outcome == -2) toast(`Slow down! You move too fast. 🎼♩♬♫🎶🎵`, { time: 3000, className: '', clickable: true, clickClosable: false });
                    if (outcome == -3) toast(`Your account is not allowed to post in here. Please email ${ssr_data_tribe.email_support} to correct this.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                    if (outcome == -4) toast(`Take a breather. You may not post in this host's comments for a bit.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                    if (outcome == -5) toast(`Oops, looks like you almost posted a duplicate!`, { time: 3000, className: '', clickable: true, clickClosable: false });
                }

            }
        });

     }


    const togglePublish = async (id) => {
      let vtoggle = bumber(toggle);
      set_toggle(vtoggle);

        toastConfig({
          time: 5000,
          className: '',
          position: 'right'
      });

      if (hasData(id)) {
        const res = await fetch(`/api/private/post/update-public?id=${id}&action=${vtoggle}`);;
        const json = await res.json()
        if (json) {

           //console.log(json)
          const { action } = json[0][0]
          //console.log(action)
          if (action==1) { 
            toast(`Post published`, { time: 3000, className: '', clickable: true, clickClosable: false });
            //mutateItemsDeleteOne(id)
            set_isDraft(false)
          } else {
            toast(`Post unpublished`, { time: 3000, className: '', clickable: true, clickClosable: false });
            //mutateItemsDeleteOne(id)
            set_isDraft(true)
          }
        }
      }
    }

    
    // const followHandler = async (id,bol_following,user_name) => {

    //   var confirmed = true;
    //   if (bol_following) {
    //     //do confirmation check
    //     confirmed = await isConfirmed(`Are you sure you want to unfollow ${user_name}?`);
    //   }
      
    //   if (confirmed) {
    //     if (hasData(id)) {
    //       const res = await fetch(`/api/private/user-follow/insert?id=${id}`);;
    //       const json = await res.json()
    //       if (json) {
    //         // console.log("json",json)
    //         const { action, status } = json
    //         //console.log("follow status", status)
    //         set_state_following_them_status(status);
    //         if (action>=1) { 
    //           await set_state_following_them(true)
    //         } else { 
    //           await set_state_following_them(false)
    //         } 
    //       }
    //     }
    //   }
      
    // }


    

     {/* TITLE */}
    const title=(<>        
                  {hasData(display_data?.post_title) && ["5"].indexOf(display_data?.post_type?.toString()) == -1 &&
                  <div className="text-left p-2">
                    <h2 className={`text-2xl font-bold`}>
                    <Link href={permaurl}>

                      {textProcess(display_data?.post_title)}

                    </Link>
                    </h2>
                  </div>
                  }</>)


    

        const prepPlayerTimestamp = async (
                                      timestamp, 
                                      file, 
                                      duration, 
                                      post_id,
                                      post_type,
                                      content_id, 
                                      content_table, 
                                      owner_id, 
                                      owner_name, 
                                      avatar_url, 
                                      start_date, 
                                      broadcast_title
                                      ) => {

                                        

        await set_player_state({});

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,post_type: post_type
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:false 
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        }

        // console.log("player_object",player_object,display_data)

        await set_player_state(player_object);

    }

    const parseTimestamp = (body,index) => {
      var inputString = body;
      const regex_id = /(([0-9]?[0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]/gm;
      inputString = inputString.replace(regex_id, (match, group1) => {
        return `<a data-timestamp="1" data-timestampindex="${index}" class="cursor-pointer text-blue-500 underline hover:text-white">${match}</a>`;
      });
      return inputString;
    }

    
    {/* POST BODY */}
    const description = (<>
                  {hasData(display_data?.post_body) && 
                  <div className='text-left' ref={descriptionRef}>
                    {parsehtml(
                      ["2","5","13"]?.indexOf(display_data?.post_type?.toString()) > -1
                      ? parseTimestamp(
                          parseMarkDown(display_data?.post_body,ssr_data_tribe?.server_website)
                          ,index //the index to use to know which post array to reference
                        )
                      : parseMarkDown(display_data?.post_body,ssr_data_tribe?.server_website)
                      )
                    }
                  </div>
                  }</>)

               

    {/* POST Tags */}
    const tags = (<>
      {hasData(display_data?.post_tags) && 
      <div className='text-left  inline'>
        {display_data?.post_tags.split(',')?.map((tag,index)=> {
          return (
            <div className="inline-flex" key={index}>
                    <Link
                      href={`/?search=${tag?.trim()?.replace(/\#/gi,'')}`}
                      className='mr-2 underline hover:no-underline cursor-pointer italic text-gray-400'>
                      #{textProcess(tag)?.trim()?.replace(/\#/gi,'')}

                    </Link>
                    </div>
          );
        })}
      </div>
      }</>)

          {/* POST Guests */}
    const guests = (<>
      {hasData(display_data?.guests_json) && 
      display_data?.guests_json !== "[]" &&
      <div className={`px2 text-left ${markdownStyles['markdown']}`}>
        <p><strong>{textProcess(`Featuring`)}</strong></p>
        <ul>
        {JSON.parse(display_data?.guests_json).map((e,index) => {
          return <li key={index}><Link href={`/${e?.name?.toLowerCase()}`}>{e?.name}</Link> ({e?.type})</li>;
        })}
        </ul>
      </div>
      }</>)

    

    return <>
    <div className="sm:px-2 w-full  inline-block  bg-gray-900 ">
      <div className={` rounded-md  bg-gray-800 shadow-md rounded-t-md
      ${(isDraft && isAuthor)
        ? 'border-2 border-dotted  border-red-700'
        : 'border  border-gray-700'
        }
      `}>

      {/* TOP ROW */}
      <div className={`flex items-center content-center text-left border-b  border-gray-700 p-2
                      ${display_data?.bol_sticky == "1" ? " bg-blue-700 rounded-t-md" : ""}
      `}>

          {/* USERNAME */}
          <div className="flex-1 flex items-center content-center text-left">

            
            <div className="flex-0 ">
            <Link href={`/${display_data?.user_name?.toLowerCase()}`} >

              <AuthorCredit
                  display_data={display_data}
                  isPlaying={display_data?.post_type=="7"}
                  profiledata={{
                    bol_flair:0
                    ,flair_json: []
                    ,id: display_data?.user_id
                    ,user_name: display_data?.user_name
                    ,bol_staff_help: display_data?.bol_staff_help
                    ,staff_json: []
                    ,bol_admin_help: display_data?.bol_admin_help
                    ,contributor_level: display_data?.contributor_level
                    ,bot_default_name: ownerdata?.bot_default_name
                    ,bot_default_image: ownerdata?.bot_default_image
                }}
                avatarClass={`w-6 h-6 mr-2 border-2  ${display_data?.contributor_level > 0 ? "border-white" : "border-gray-500 opacity-90"}`}
                
                show_flair={false}
              />

            </Link>
            </div>
            <div className="flex-1 pl-4  text-gray-400 text-sm">
              {/* <ChatTimer
                  start_date={+new Date(display_data?.publish_date)}
                  timestamp={+new Date(display_data?.timestamp)}
                  time={time}
              /> */}
            </div> 
            {display_data?.bol_sticky?.toString() == "1" && 
            <div className={`flex-0 pl-4 mr-1 text-white`}>
                  <SvgJsx 
                      type={"fill"}
                      icon={"thumbtack-sm"}
                      className={`h-4 w-4 `}
                      title={textProcess("sticky post")}
                  />
              
            </div> 
             }


          </div>

          <div className="cursor-pointer text-gray-400 hover:text-white flex items-center content-center"
            onClick={()=>{
              set_clip_state({status:0,auto:1,start:0,track:0,end:0});
              openModal("share",itemdata?.post_id,itemdata?.post_type);
            }}
          >
            <SvgJsx 
                type='outline' 
                icon='share' 
                className={`  w-5 h-5 sm:p-1
                            `} 
                title='Share'
            />  
            <div className="ml-1 text-xs hidden sm:block">{textProcess(`Share`)}</div>
          </div>

          {itemdata?.id?.toString() !== userdata?.id?.toString() &&
          <div className="mr-1">
                
                      <ProfileFollow
                                classColor={
                                    (loading) ? `bg-gray-500 text-white cursor-progress animate-pulse`
                                              : (!loading && !session)
                                                ? `bg-brand hover:bg-blue-600 text-white`
                                                : (!loading && session && itemdata?.id != userdata?.id)
                                                  ? `${state_following_them ? 'bg-gray-500' : 'bg-brand hover:bg-blue-600'}  text-white`
                                                  : `hidden`
                                  } 

                                onClick={()=> {
                                  //loading
                                  if (loading) toast(`Still loading. Please wait a moment...`, { time: 3000, className: '', clickable: true, clickClosable: true });
                                  if (!loading && !session) signIn();
                                  if (!loading && session && itemdata?.user_id != userdata?.id) {
                                    
                                    followHandler(
                                      itemdata?.user_id,
                                      (state_following_them && state_following_them_status >= 1),
                                      itemdata?.user_name,
                                      isConfirmed,
                                      set_state_following_them_status,
                                      set_state_following_them
                                    );
                                  }
                                }}
                                notifyHandler={()=> {
                                  //loading
                                  if (loading) return;
                                  if (!loading && !session) return;
                                  if (!loading && session && itemdata?.user_id != userdata?.id) return notifyHandler
                                }}
                                message={ (loading) 
                                            ? textProcess(`Follow`)
                                            : (!loading && !session)
                                              ? textProcess(`Follow`)
                                              : (!loading && session && itemdata?.user_id != userdata?.id)
                                                ? (state_following_them && state_following_them_status >= 1) 
                                                  ? textProcess(`Following`)
                                                  : state_following_them && state_following_them_status < 1
                                                    ? textProcess('Requested')
                                                    : textProcess('Follow')
                                                : textProcess(`Follow`)}
                              
                                ownerdata={itemdata}
                                show_options={(state_following_them && state_following_them_status >= 1) ? true : false}
                                _bol_notify_options={_bol_notify_options}
                                set_bol_notify_options={set_bol_notify_options}
                                _notify_options_bitarray={_notify_options_bitarray}
                                set_notify_options_bitarray={set_notify_options_bitarray}
                                _notify_show_array={_notify_show_array}
                                set_notify_show_array={set_notify_show_array}
                                showCondition={true}
                                classSize={`text-xs `}
                                shrink={true}
                              />
                              

          </div>
          }

            
          

          <div className="flex-0  text-right flex items-center content-center justify-end">
            <Link
              href={permaurl}
              className=" cursor-pointer w-full justify-end flex
                          text-gray-600 hover:text-gray-400">

              <div className="flex items-center content-center justify-end">
                <div className="flex-1">
                  <div className="  text-gray-400 hover:text-white rounded-md p-1 text-2xs text-center">
                    
                      <SvgJsx 
                          type={'fill'}
                          icon={lookupPost("",display_data?.post_type).icon}
                          className={` h-5 w-5 group-hover:animate-wiggle group-hover:opacity-80`}
                          title={lookupPost("",display_data?.post_type).type}
                      />
                  </div>
                </div>
                 
              </div>

            </Link>
          </div> 

          {/* OPTIONS */}
          <div className="flex-0 flex ">
            <PostMenuOptions 
              item_id={display_data?.post_id}
              item_name={display_data?.post_title}
              userdata={userdata}
              owner_id={display_data?.user_id}
              timestamp={display_data?.timestamp}
              session={session}
              openModal={openModal}
              closeModal={closeModal}
              cache={cache}
              set_cache={set_cache}
              permaurl={permaurl}
              isAdmin={isAdmin}
              isContributor={display_data?.contributor_level >= 1}
              post_date={friendlydate(post_date)}
              post_type={display_data?.post_type}
              deleteHandler={deleteHandler}
              stickyHandler={stickyHandler}
              transcribeHandler={transcribeHandler}
              bol_replay_host={display_data?.bol_replay_host}
              bol_transcribe={display_data?.bol_transcribe}
              transcribe_status={display_data?.transcribe_status}
              bol_sticky={display_data?.bol_sticky}
              player_state={player_state}
              set_player_state={set_player_state}
              file={extractJson(display_data?.post_contents_json,"url")}
              duration={extractJson(display_data?.post_contents_json,"duration")}
              content_id={display_data?.content_id}
              content_table={display_data?.content_table}
              owner_name={display_data?.user_name}
              avatar_url={getAvatarUrl(
                display_data?.avatar_url
                ,display_data?.avatar_url_sfw
                ,display_data?.bol_nsfw
                ,display_data?.avatar_crdate
                ,''
                ,display_data?.user_id
              )}
              start_date={extractJson(display_data?.post_contents_json,"start")}
              stream_status={hasData(extractJson(display_data?.post_contents_json,"end")) ? "0" : "1"}
              clip_state={clip_state}
              set_clip_state={set_clip_state}
           />
          </div>


      </div>

      {/* SPECIAL CONTENT TYPES */}
      <div>

          {/* SHOWREELS */}
          {["5"].indexOf(display_data?.post_type?.toString()) > -1 && 
          <div className='flex justify-center flex-col p-2'>
            {hasData(display_data?.post_contents_json) && 
                JSON.parse(display_data?.post_contents_json).filter((e)=>hasData(e?.url)).map((e,index)=> {

                  

                  return (<div key={index}>
                    <BroadcastSingle
                        key={index}
                        itemdata={{ 
                                    content_id:display_data?.content_id,
                                    content_table:'broadcast',
                                    //broadcast_url:broadcast_object?.url?.replace(`${broadcast_object?.hash}.${broadcast_object?.extention}`,''),
                                    broadcast_url: e?.url?.replace(`${e?.hash}.${e?.extention}`,''),
                                    
                                    broadcast_title:display_data?.post_title,
                                    hash:e?.hash,
                                    recording_hash:e?.hash,
                                    size:broadcast_object?.size,
                                    source:broadcast_object?.source,
                                    broadcast_category:broadcast_object?.category,
                                    duration:e?.duration,
                                    extention:e?.extention,
                                    post_type:broadcast_object?.post_type,
                                    broadcast_thumbnail:broadcast_object?.thumbnail,
                                    start_date: broadcast_object?.start_date, //isodate(broadcast_object?.start_date),
                                    end_date: broadcast_object?.end_date, //isodate(broadcast_object?.end_date),
                                    metadata_json:broadcast_object?.metadata_json,
                                    timestamp:display_data?.timestamp,
                                    user_id:display_data?.user_id,
                                    user_name:display_data?.user_name,
                                    avatar_url: getAvatarUrl(
                                       display_data?.avatar_url
                                      ,display_data?.avatar_url_sfw
                                      ,display_data?.bol_nsfw
                                      ,display_data?.avatar_crdate
                                      ,''
                                      ,display_data?.user_id
                                    ),
                                    broadcast_description:display_data?.post_description,
                                    broadcast_tags: "[]",
                                    bol_recording: 1,
                                    bol_public: 1,
                                    bol_status: 2,
                                    guests_json: broadcast_object?.guests_json,
                                    broadcast_hash: broadcast_object?.hash,
                                    crdate: broadcast_object?.start_date, //isodate(broadcast_object?.start_date),
                                    schedule_start: '',
                                    schedule_end: '',
                                    staff_json: "[]",
                                    bol_admin_help: 1,
                                    bol_staff_help: 1,
                                    bolrealdata: 1,
                                    owner: 0,
                                  }}
                        hostsjsondata={[]}
                        hostsjsondataisLoading={false}
                        hostsjsondataisError={false}
                        placeholderdata={itemdata}
                        userdata={userdata}
                        owner_id={1}
                        owner_name={'gross'}
                        placeholder={false}
                        display_view={"post"}
                        openModal={openModal}
                        closeModal={closeModal}
                        cache={cache}
                        set_cache={set_cache}
                        navtype={navtype}
                        helptype={helptype}
                        timestamp={timestamp}
                        post_override={true}
                        permaurl={permaurl}
                        post_id={display_data?.post_id}
                    />
                    </div>
                  )
                }
              )
            }
          </div>
          }

      </div>
        

        {/* MAIN CONTENT */}
        <div className="">

          {/* TITLE & DESCRIPTION FOR BLOG (0) AND IMAGE POSTS (1) and YOUTUBE (14)  */}
          {isFlat && //check if we're on the slug or not
          ["0","1","14"].indexOf(display_data?.post_type?.toString()) > -1 &&
          <div className="text-left px-2">
            <div>
            {title}
            </div>
           
            
          </div> 
          }

          
          {/* AUDIO FILES */}
          {["2","3","13"].indexOf(display_data?.post_type?.toString()) > -1 && 
          <div>
            
            <div className='flex justify-center flex-col'>
              {hasData(display_data?.post_contents_json) && 
                JSON.parse(display_data?.post_contents_json).filter((e)=>hasData(e?.url)).map((e,index)=>
                  <div key={index}
                    className=" flex justify-center flex-col"
                    
                  >
                   <div
                      className="p-2"
                    >
                      

                      <BroadcastSingle
                        itemdata={{ 
                                    content_id:display_data?.post_id,
                                    content_table:'post',
                                    broadcast_url:e?.url?.replace(`${e?.hash}.${e?.extention}`,''),
                                    broadcast_title:display_data?.post_title,
                                    hash:broadcast_object?.hash,
                                    recording_hash:broadcast_object?.hash,
                                    size:broadcast_object?.size,
                                    source:broadcast_object?.source,
                                    broadcast_category:broadcast_object?.category,
                                    duration:e?.duration,
                                    extention:broadcast_object?.extention,
                                    post_type:broadcast_object?.post_type,
                                    broadcast_thumbnail:broadcast_object?.thumbnail,
                                    start_date:display_data?.publish_date, //isodate(display_data?.publish_date),
                                    end_date:display_data?.publish_date, //isodate(addSeconds(display_data?.publish_date,e?.duration)),
                                    metadata_json:broadcast_object?.metadata_json,
                                    timestamp:display_data?.timestamp,
                                    user_id:display_data?.user_id,
                                    user_name:display_data?.user_name,
                                    avatar_url:getAvatarUrl(
                                      display_data?.avatar_url
                                      ,display_data?.avatar_url_sfw
                                      ,display_data?.bol_nsfw
                                      ,display_data?.avatar_crdate
                                      ,''
                                      ,display_data?.user_id
                                    ),
                                    broadcast_description:display_data?.post_description,
                                    broadcast_tags: "[]",
                                    bol_recording: 1,
                                    bol_public: 1,
                                    bol_status: 2,
                                    guests_json: display_data?.guests_json,
                                    broadcast_hash: broadcast_object?.hash,
                                    crdate: display_data?.publish_date, //isodate(display_data?.publish_date),
                                    schedule_start: '',
                                    schedule_end: '',
                                    staff_json: "[]",
                                    bol_admin_help: 1,
                                    bol_staff_help: 1,
                                    bolrealdata: 1,
                                    owner: 0,
                                  }}
                        hostsjsondata={[]}
                        hostsjsondataisLoading={false}
                        hostsjsondataisError={false}
                        placeholderdata={itemdata}
                        userdata={userdata}
                        owner_id={1}
                        owner_name={'gross'}
                        placeholder={false}
                        display_view={"post"}
                        openModal={openModal}
                        closeModal={closeModal}
                        cache={cache}
                        set_cache={set_cache}
                        navtype={navtype}
                        helptype={helptype}
                        timestamp={timestamp}
                        post_override={true}
                        permaurl={permaurl}
                        post_id={display_data?.post_id}
                
                    />
                  </div>

                    
                    
                    
                  </div>
                )
              } 
            </div>
          </div>
          }

          
          {/* TITLE & DESCRIPTION FOR ALL BUT TEXt, AUDIO AND VOICE MEMOS AND ENDED SHOW REELS. ALSO INCLUDe IMAGES SO WE CAN JUDGE CONTAINER HEIGHT */}
          {["0","1","2","3","5","13","14"].indexOf(display_data?.post_type?.toString()) > -1 &&
          <div className="px-2 markdown">

           
            <div 
              className={` block relative ${!isExpanded 
                                            ? (["1","14"])?.indexOf(display_data?.post_type?.toString()) > -1
                                              ? `h-[156px] ` //visual types
                                              : `h-[56px] ` //non-visual types
                                            : ""}`}
             
            >
              <div
              ref={containerRef}
              >
                <div 
                    className={`z-1 w-full transition transition-slowest ease duration-100  ${!isExpanded 
                      ? (["1","14"])?.indexOf(display_data?.post_type?.toString()) > -1
                        ? `h-[156px] absolute overflow-hidden` //visual types
                        : `h-[56px] absolute overflow-hidden` //non-visual types
                      : ""}`}
                    
                >
                    <div className={`px2 ${markdownStyles['markdown']}`}>

                      
                      

                      {/* FILES */}
                      {["1"].indexOf(display_data?.post_type?.toString()) > -1 && 
                      <div>
                        <div className='flex justify-center flex-col'>
                          {hasData(display_data?.post_contents_json) && 
                            JSON.parse(display_data?.post_contents_json).filter((e)=>hasData(e?.url)).map((e,index)=>
                              <div key={index}
                                className=" flex justify-center flex-col"
                                
                              >
                                {["1"].indexOf(e?.post_type?.toString()) > -1 &&
                                <div 
                                  className={`items-center  content-center justify-center`}
                                >
                                  <div 
                                        className={``}
                                        
                                    >
                                      <Image
                                          src={e?.url}
                                          alt={e?.alt}
                                          title={e?.title}
                                          width={e?.width}
                                          height={e?.height}
                                          className="mx-auto"
                                        />

                                        <div className="markdown">
                                          <div className="italic">
                                            {e?.caption}
                                          </div>
                                        </div>

                                    </div>
                                    
                                  
                                </div>
                                }
                                
                              </div>
                            )
                          } 
                        </div>
                      </div>
                      }

                      {/* VIDEO EMBEDS */}
                      {["14"].indexOf(display_data?.post_type?.toString()) > -1 && 
                      <div
                        className=" w-full   text-center rounded-b-md flex justify-center cursor-pointer"
                      >
                      <div className="flex  items-center content-center w-full">
                        <div className={`h-full w-full 
                          ${display_data?.post_body?.indexOf("/shorts") > -1
                            ? `min-h-[425px]`
                            : `xs:min-h-[225px] sm:min-h-[425px]`
                          }
                          
                        `}>
                            {isMounted &&
                            <ReactPlayer 
                              url={display_data?.post_body} 
                              controls={true}
                              width="100%"
                              height="100%"
                              pip={true}
                              style={{position: 'relative'}}
                            />
                            }

                          </div>
                        </div>
                      </div>
                    }
                    
                    {/* EVERYTHING ELSE */}
                      <div className="p-2">
                        {description} 
                        {guests}
                      </div>
                      
                      
                    
                    </div>


                </div>
              </div>
              
              {!isExpanded &&
              <div className="group">
                <div 
                    className={`absolute z-5  cursor-pointer w-full bg-gradient-to-t from-gray-800 to-transparent  ${!isExpanded 
                      ? (["1","14"])?.indexOf(display_data?.post_type?.toString()) > -1
                        ? `h-[156px] ` //visual types
                        : `h-[56px] ` //non-visual types
                      : ""}`}
                    onClick={()=> {
                      openHandler(display_data?.post_id,display_data?.post_type)
                    }}
                >
                    
                </div>  
  
                <div 
                    className={`pt-5 mb-2 z-10  w-full group-hover:visible invisible`}
                >
                    <div className="absolute bottom-0 mx-auto w-full flex  cursor-pointer"
                      onClick={async ()=> {
                        openHandler(display_data?.post_id,display_data?.post_type);
                        updateTitle(title_format(display_data?.post_title,ssr_data_tribe));
                        window.history.pushState("object or string", title_format(display_data?.post_title,ssr_data_tribe), permaurl);
                        updateTitle(title_format(display_data?.post_title,ssr_data_tribe));
                        
                      }}
                    >
                      <div className="bg-blue-600 text-white px-4 py-1 text-lg font-bold rounded-full flex-0 mx-auto mb-4">
                      {textProcess(`View Post`)}
                      </div>
                    </div>
                </div> 
              </div>
              }
             
            </div>
            

            {hasData(display_data?.post_tags) && 
            <div className="pb-2 pl-2 text-xs text-left">
              {tags}
            </div>
             }


          </div> 
          }

          {/* SHOW ALERTS */}
          {["7"].indexOf(display_data?.post_type?.toString()) > -1 && 
          <div
            className=" w-full  p-5 text-center rounded-b-md flex justify-center cursor-pointer"
          >
            
            <div className="flex  items-center content-center ">
              
              <div className=" font-bold text-3xl"
              
              >
                <h2>
                {textProcess(`Live show in progress!`)}
                </h2>
              </div>
            </div>
            
          </div>
          }

          {/* ANNIVERSARY (11) */}
          {["11"].indexOf(display_data?.post_type?.toString()) > -1 &&
          <div
          className=" w-full  p-5 text-center rounded-b-md flex justify-center cursor-pointer"
        >
          
          <div className="flex flex-col items-center content-center ">
            
            <div className=" font-bold text-md sm:text-xl"
            
            >
              <h2>
              {display_data?.user_name} {textProcess(`joined`)} {ssr_data_tribe?.brand_name} {textProcess(`on`)} {formatDate(display_data?.publish_date)}!
              </h2>
            </div>
            <div className="mt-5 opacity-50">
              <Image 
                src={`/images/fun/undraw_city_life_gnpr.svg`}
                width={500}
                height={100}
                title={'Welcome Cats by Undraw.co'}
                priority
              />
            </div>
          </div>
          
        </div>
         
          }


          
        </div>

       

       


        {/* DRAFT BAR */}
        {(isDraft && isAdmin) &&
        <div className="flex justify-center border-t border-gray-700 py-2">
          
          <div className=" font-bold flex-0 text-sm cursor-pointer opacity-100 hover:opacity-75 bg-green-600 text-white hover:bg-green-500 px-4 py-2 rounded-full"
            onClick={()=> togglePublish(display_data?.post_id)}
          >
            Publish this Draft
          </div>

          <div className="ml-2 flex-0 text-sm cursor-pointer opacity-100 hover:opacity-75 bg-red-600 text-white hover:bg-red-500 px-4 py-2 rounded-full"
            onClick={()=> deleteHandler(display_data?.post_id)}
          >
            Delete
          </div>

        </div>
        }

        {/* INTERACTION BAR - ALERTS */}
        {(display_data?.post_type?.toString() == "7") &&
        <div className="flex items-center content-center border-t border-gray-700 py-2 px-2">
          
          <div className="flex-1 text-left flex items-center content-center ">
            <div className="flex-0">
              
            </div>
            <div
              className="flex-1 flex  italic py-1 pl-2 text-gray-400"
            >
              
            <Link
              href={`/${display_data?.user_name?.toLowerCase()}/chat`}
              className="cursor-pointer hover:bg-red-500 bg-red-600 animate-pulse text-white px-5 py-2 font-bold rounded-md">
                
                {textProcess(`Open the live chat`)}
                
                </Link>
            </div>
          </div>


          {!commenting && 
         
          
          <div className="flex-0 flex items-center content-center ml-1">

            {/* LIKES */}
            {display_data?.bol_likes?.toString() == "1" &&
            <div className="flex-0 ml-1 p-2 pr-2 cursor-pointer flex items-center content-center rounded-md group
            border border-gray-800 hover:border-gray-500 font-thin text-sm
            "
            onClick={()=>likeHandler(display_data?.post_id,display_data?.user_id,userdata?.id)}
            >
              <div className="flex-0 mr-1 min-w-[16px]">
                {likes}
              </div>
              <div className="flex-0">
                <SvgJsxNew
                    type={"outline"}
                    icon={"heart"}
                    className={`h-6 w-6  group-hover:text-red-600`}
                    title={formatPlural('like',likes)}
                />
              </div>
              
            </div>
            }
          </div>
          }
         
        </div>
        }




        {/* INTERACTION BAR - COMMENTS */}
        {(!isDraft && display_data?.post_type?.toString() !== "7") &&
        <div className="flex items-end content-end border-t border-gray-700 bg-gray-700 rounded-b-md">
          
          <div className="flex-1 text-left ">
            {show_comments &&
            <form 
              method="post" 
              id="msgform" 
              name="msgform" 
              onSubmit={(e) => {
               
                  commentHandler(e,msg,display_data?.post_id,permaurl,set_comments);
                 
                 
              }}
              >
              <div className="w-full flex items-end content-end "
              
                onFocus={()=>set_commenting(true)}
                // onBlur={()=>set_commenting(false)}
              >

                <div className="flex-1 flex items-center content-center ">
                  <TextareaAutosize
                    placeholder={`Add a Comment...`}
                    className='w-full text-md border-transparent  rounded-md   text-white bg-gray-700  '
                    value={msg}
                    id={'msg'}
                    onChange={(e)=> { 
                      set_msg(e.target.value)
                      set_commenting(true)
                    }}
                    onKeyDown={async (e)=> {if ([13].indexOf(e.keyCode) > -1 && !e.shiftKey) {
                      msgRef.current.focus();
                      commentHandler(e,msg,display_data?.post_id,permaurl,set_comments);
                    }}}
                    minRows={1}
                    ref={msgRef}
                  />
                </div>

                {/* CHECK/SEND */}
                {commenting && 
                <div className="flex-0 flex items-center content-center mb-0.5">
                  <div className="flex flex-0 items-center content-center">
                    <div
                        role="button"
                        aria-pressed="false"
                        tabIndex={0}
                        className="cursor-pointer font-bold text-white hover:text-gray-200 flex text-xl pl-1" 
                        onClick={async (e) => {
                          msgRef.current.focus()
                          commentHandler(e,msg,display_data?.post_id,permaurl,set_comments);
                        }}
                        onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                          msgRef.current.focus();
                          commentHandler(e,msg,display_data?.post_id,permaurl,set_comments);
                        }}}
                    >
                        <div className="flex items-end content-end">
                          
                            <div className="flex-0 ">
                                <SvgJsx 
                                    type={'fill'}
                                    icon={'check-circle-sm'}
                                    className={` h-10 w-10`}
                                    title={'SEND'}
                                />
                            </div>
                        </div>                    
                    </div>
                  </div>
                

                  {/* X/CANCEL */}
                  <div className="flex items-end content-end pr-2">
                      <div
                        role="button"
                        aria-pressed="false"
                        tabIndex={0}
                        className="cursor-pointer font-bold text-gray-500 hover:text-gray-400 flex text-xl  pl-1" 
                        onClick={async (e) => {

                          if (msg?.length > 0) {
                            const confirmed = await isConfirmed("You have a comment you have not posted yet. Are you sure you want to cancel?");
                            // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
                           if (!confirmed) {
                            return false
                            }
                          }
                                
                            set_commenting(false);
                            set_msg('');
                                
                          }}
                      >
                        <div className="flex-0 ">
                            <SvgJsx 
                              type={'fill'}
                              icon={'x-sm'}
                              className={` h-6 w-6`}
                              title={'cancel'}
                            />
                        </div>
                      </div>
                  </div>
                </div>
                }
              </div>
              
              
            </form>
            } 
            {!show_comments &&
            <div
              className="italic py-1 pl-2  text-gray-400"
            >
                <div className="flex items-center content-center text-center italic text-gray-200">
                    <div className="flex-0">
                        <div className="rounded-full  text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <div className="ml-2 flex-0 py-1">
                        {textProcess('Responses are disabled')}
                    </div>
                </div>
            </div>
            }
          </div>


          {!commenting && 
         
          
          <div className="flex-0 flex items-center content-center ">

             

            {/* COMMENTS */}
            {show_comments &&
            <div className={`flex-0 flex items-center content-center`}>
              <div
                className="flex-0 group"
                onClick={()=>likeHandler(display_data?.post_id,display_data?.user_id,userdata?.id)}
              >
                
                
                
                      <div className={`flex-0 text-3xl flex cursor-pointer m-1 group-hover:animate-wiggle group-hover:bg-gray-600 rounded-md
                                          
                                           
                        `}>
                          {itemdata?.chat_emoji}
                        </div>
                      
                
              </div>
              <div
                className="group flex items-center content-center mr-2 gap-x-2 px-2 py-1 hover:bg-gray-600 rounded-md cursor-pointer"

                onClick={async ()=> {
                  await openHandler(display_data?.post_id,display_data?.post_type);
                  await metaScroll();
                }}
                >
                  
               
                <div>
                    
                    <SvgJsx 
                                  type={'outline'}
                                  icon={'chat-bubble-left-sm'}
                                  className={` h-7 w-7 group-hover:opacity-80 group-hover:animate-wiggle`}
                                  title={`${textProcess('See')} ${(comments+likes)} ${formatPlural(textProcess("response"),(comments+likes))}`}
                              />
                </div>
                <div className="text-md">
                {(comments+likes)}
                </div>
              </div>
            </div>
            }
          </div>
          }
         
        </div>
        }


      </div>
    </div>
    </>;

   
}

  