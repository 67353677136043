
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import { config, lookupPost } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import { timerStrDHMS } from "@/lib/utils/timerStr";
import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

function PostStats({
     post_id
     ,profiledata
    ,slug
    ,router
    ,cache
    ,set_cache
    ,opens
}) {

  const [statarraydata_active,set_statarraydata_active] = useState([]);
  const [statarraydailydata_active,set_statarraydailydata_active] = useState([]);

  //POST STAT DATA
  const { 
    data: statarraydata
  , loading: statarraydataisLoading
  , error: statarraydataisError
  //, boundMutate: itemsMutate
                  } = useSWRWrapper(
                    post_id //id
                    ,`/api/private/post/select-stats?id=${post_id}&cache=${cache}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

    useEffect(()=>{
        if (!statarraydataisLoading && !statarraydataisError) {
            set_statarraydata_active(statarraydata);
        }
    },[
        statarraydata
        ,statarraydataisLoading
        ,statarraydataisError
    ])

  //POST STATS DATA DAILY
  const { 
    data: statarraydailydata
  , loading: statarraydailydataisLoading
  , error: statarraydailydataisError
  //, boundMutate: itemsMutate
                  } = useSWRWrapper(
                    post_id //id
                    ,`/api/private/post/select-stats-daily?id=${post_id}&cache=${cache}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

    useEffect(()=>{
        if (!statarraydataisLoading && !statarraydataisError) {
            set_statarraydata_active(statarraydata);
        }
    },[
        statarraydata
        ,statarraydataisLoading
        ,statarraydataisError
    ])

    useEffect(()=>{
        if (!statarraydailydataisLoading && !statarraydailydataisError) {
            set_statarraydailydata_active(statarraydailydata);
        }
    },[
        statarraydailydata
        ,statarraydailydataisLoading
        ,statarraydailydataisError
    ])

    
  const options = {
    plugins: {
      title: {
        display: true,
        text: `Replays over the last 30 days`,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
            stepSize: 1,
            beginAtZero: true,
        }
      },
    },
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  


  const data = {
    labels: statarraydailydata?.map((b)=> {return `${new Date(b?.view_date).toLocaleDateString('en-US')}`}),
    datasets: [
      {
        label: 'Replays',
        data: statarraydailydata?.map((b)=> {return b?.plays}),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
    ],
  };

  const renderLineChart = (<div className="h-[350px] w-full px-2">
                            <Bar options={options} data={data} />
                            </div>)

    return(<>

                {/* TOP ROW */}
                <div 
                    className="flex w-full flex-col items-center content-center text-left divide-y  divide-gray-700"
                >

                    {statarraydataisLoading && 
                    <div className="p-10">
                         <LoadingProcessing />
                    </div>}

                    {!statarraydataisLoading &&
                    <>
                      <ol className=" w-full ml-10 my-5">
                        <li className="ml-5 list-disc">ID: {statarraydata_active[0]?.post_id}</li>
                        <li className="ml-5 list-disc">Title: {statarraydata_active[0]?.post_title}</li>
                        {hasData(statarraydata_active[0]?.post_description) && <li className="ml-5 list-disc">Description: {statarraydata_active[0]?.post_description}</li>}
                        <li className="ml-5 list-disc">Type: {lookupPost("",statarraydata_active[0]?.post_type).type}</li>
                        <li className="ml-5 list-disc">Published on: {new Date(statarraydata_active[0]?.publish_date)?.toUTCString()}</li>
                        {statarraydata_active[0]?.post_type?.toString() == "5" &&
                        <>
                          <li className="ml-5 list-disc">
                            Broadcast Started: {new Date(statarraydata_active[0]?.start_date)?.toUTCString()}
                          </li>
                          <li className="ml-5 list-disc">
                            Broadcast Ended: {new Date(statarraydata_active[0]?.end_date)?.toUTCString()}
                          </li>
                        </>
                        }
                        {["5"].indexOf(statarraydata_active[0]?.post_type?.toString()) > -1 && 
                        <li className="ml-5 list-disc">Duration: {timerStrDHMS(statarraydata_active[0]?.duration)}</li>
                        }
                        {["2","13"].indexOf(statarraydata_active[0]?.post_type?.toString()) > -1 && 
                        <li className="ml-5 list-disc">Duration: {IsJsonValid(statarraydata_active[0]?.post_contents_json) ? timerStrDHMS(JSON.parse(statarraydata_active[0]?.post_contents_json)[0]?.duration) : '-'}</li>
                        }
                        <li className="ml-5 list-disc">Opens: {opens}</li>
                      </ol>

                      {["2","5","13"].indexOf(statarraydata_active[0]?.post_type?.toString()) > -1 && 
                      <div className="px-5 w-full ">
                        <div>
                            <div className="w-full mb-5 text-center flex justify-center">
                                {renderLineChart}
                            </div>
                        </div>
                        <div className="table w-full rounded-md border border-gray-700 p-2  mt-5">
                            <div className="table-header-group  border border-gray-600">
                                <div className="table-row text-white bg-gray-950 border border-gray-200 font-bold text-sm sm:text-xl">
                                    <div className="table-cell   p-2">
                                        Recording Stats
                                    </div>
                                    <div className="table-cell   p-2 text-center w-32 sm:w-48 ">
                                        Replays
                                    </div>
                                </div>
                            </div>

                            <div className="table-row-group group bg-gray-700 hover:opacity-90  font-bold">
                                <div className="table-row">
                                    <div className="table-cell  p-2">
                                    Total
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_plays || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell  pl-5 text-left p-2">
                                    Guests
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {(statarraydata_active[0]?.sum_plays_guests) || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell  pl-5 text-left p-2">
                                    Members
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_plays_members || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Followers
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_plays_followers || 0}
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      }
                      
                      {["5"].indexOf(statarraydata_active[0]?.post_type?.toString()) > -1 && 
                      <div className="px-5 w-full ">
                        <div className="table w-full rounded-md border border-gray-700 p-2 mt-5 ">
                            <div className="table-header-group  border border-gray-600">
                                <div className="table-row text-white bg-gray-950 border border-gray-200 font-bold text-sm sm:text-xl">
                                    <div className="table-cell   p-2">
                                        Live Show Stats
                                    </div>
                                    <div className="table-cell   p-2 text-center w-32 sm:w-48 ">
                                        Listeners
                                    </div>
                                    <div className="table-cell   text-center p-2 w-32 sm:w-48">
                                        Chatters
                                    </div>
                                </div>
                            </div>
                            
                            <div className="table-row-group group bg-gray-700 hover:opacity-90  font-bold">
                                <div className="table-row">
                                    <div className="table-cell   p-2">
                                    Peak
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.peak_listeners || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.peak_chatters || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell  pl-5 text-left p-2">
                                    Guests
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {(statarraydata_active[0]?.peak_listeners_guests) || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {(statarraydata_active[0]?.peak_chatters_guests) || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Members
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.peak_listeners_members || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.peak_chatters_members || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group bg-gray-700 hover:opacity-90  font-bold">
                                <div className="table-row">
                                    <div className="table-cell  p-2">
                                    Total
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_listeners || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_chatters || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Guests
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {(statarraydata_active[0]?.sum_listeners_guests) || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {(statarraydata_active[0]?.sum_chatters_guests) || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell  pl-5 text-left p-2">
                                    Members
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_listeners_members || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_chatters_members || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Followers
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_listeners_followers || 0}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_chatters_followers || 0}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group bg-gray-700 hover:opacity-90  font-bold">
                                <div className="table-row">
                                    <div className="table-cell  p-2">
                                    Time Spent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_listen_time || 0,true)}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_chat_time || 0,true)}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell  pl-5 text-left p-2">
                                    Guests
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_listen_time_guests || 0,true)}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_chat_time_guests || 0,true)}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell  pl-5 text-left p-2">
                                    Members
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_listen_time_members || 0,true)}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_chat_time_members || 0,true)}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Followers
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_listen_time_followers || 0,true)}
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {timerStrDHMS(statarraydata_active[0]?.total_chat_time_followers || 0,true)}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group bg-gray-700 hover:opacity-90  font-bold">
                                <div className="table-row">
                                    <div className="table-cell  p-2">
                                    Misc Stats
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Messages Sent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_chats}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Emojis Sent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_emojis}
                                    </div>
                                </div>
                            </div>
                            <div className="table-row-group group  hover:opacity-90">
                                <div className="table-row">
                                    <div className="table-cell pl-5 text-left p-2">
                                    Gifs Sent
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    
                                    </div>
                                    <div className="table-cell   text-center p-2">
                                    {statarraydata_active[0]?.sum_gifs}
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      }
                
                    
                  

                    </>}


                
                </div>


    </>)
}

export default PostStats