import { ModalContext } from "@/components/context/modal-wrapper";
import { PlayerContext } from "@/components/player/player-wrapper";
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import AuthorCredit from "@/components/templateux/user/author-credit";
import { config} from "@/lib/config";
import { countUpBySeconds } from "@/lib/utils/countUp"
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import isAdminUser from "@/lib/utils/isAdminUser";
import { timerFormat } from "@/lib/utils/timerFormat"
import Link from "next/link";
import {  useContext, useEffect,  useState } from "react";
import PostArray from "./post-array";

export default function PostClip({
    file
    ,duration
    ,post_id
    ,content_id
    ,content_table
    ,profiledata
    ,userdata
    ,ownerdata
    ,ssr_data_tribe
    ,descriptionRef
    ,session
    ,owner_id
    ,owner_name
    ,avatar_url
    ,broadcast_title
    ,start_date
    ,router
    ,origin_url
    ,origin_title
}) { 

    const [isMounted,set_isMounted] = useState(false)
    useEffect(()=>{set_isMounted(true)},[])

    const {isOpen,set_isOpen} = useContext(ModalContext)

    const {player_state,set_player_state} = useContext(PlayerContext)

    const [cache,set_cache] = useState('')

    // const [active_sentence,set_active_sentence] = useState({seek:0,index:`0-0`})

    //SWR to retrieve all chat messages here
    const { 
        data: postcliparraydata
      , loading: postcliparraydataisLoading
      , error: postcliparraydataisError
      , boundMutate: itemsMutate
                      } = useSWRWrapper(
                        post_id?.toString() //id
                        ,`/api/private/post/select?id=${post_id}&option=parent&cache=${cache}` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,0  //refreshinterval (0=none)
                        )   
                    

    if (postcliparraydataisLoading) {
        return (<> <div className="p-10">
                                <LoadingProcessing />
                        </div></>)
    }

    if (!postcliparraydataisLoading) {

       

        return (<>
                    <div className="">

                        {postcliparraydata.length == 0 &&
                         <div className="text-center py-10 italic text-gray-200">
                        No clips to show.
                        </div>
                        }
                        {postcliparraydata.length > 0 && 
                            postcliparraydata?.map((e,index) => {


                                return (<div key={index}>
                                    <PostArray 
                                        itemdata={postcliparraydata}
                                        isLoading={ false}
                                        isError={ false }
                                        userdata={userdata}
                                        ownerdata={ownerdata}
                                        display_view={'audio'}
                                        set_display_view={()=>{}}
                                        cache={cache}
                                        set_cache={set_cache}
                                        slugfilter={''}
                                        placeholderdata={[
                                        //   {
                                        //    post_title: 'Loading'
                                        //   ,crdate: +new Date().toISOString()
                                        //   ,start_date: +new Date().toISOString()
                                        //   ,end_date: +new Date().toISOString()
                                        //   ,post_contents_json: []
                                        //   ,bol_status: -1
                                        //   ,bol_public: 0
                                        //   ,bol_recording: 0
                                        //   ,user_id: profiledata?.id
                                        //   ,user_name: 'Lurker'
                                        //   ,avatar_url: ''
                                        //   ,post_id: -1
                                        // }
                                        ]}
                                        nodata={'No clips to show.'}
                                        navtype={'audio'}
                                        helptype={'post'}
                                        isAdmin={isAdminUser(profiledata?.id            //user_id
                                                    ,profiledata?.bol_staff_help    //bol_staff_help
                                                    ,profiledata?.staff_json        //staff_json
                                                    ,profiledata?.bol_admin_help    //bol_admin_help
                                                    ,userdata?.id?.toString()                            //my_id
                                                    ,userdata?.bol_admin?.toString()               //global_admin
                                                    ,'chat'
                                                    )}
                                        slug={''}
                                        title={'All posts'}
                                        swr_url={`/api/private/post/select?id=${post_id}&option=parent&cache=${cache}`}
                                        descriptionRef={descriptionRef}
                                        ssr_data_tribe={ssr_data_tribe?.tribe_id}  
                                        origin_url={origin_url}
                                        origin_title={origin_title}
                                    />
                                </div>)

                        })}
                    </div>
                               
                   </>)
    }
    
}