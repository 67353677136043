import { hasData } from "./hasData"

export function getSlugFilter(slug) {
    var slugfilter = slug || ''

    if (hasData(slugfilter)) {
      slugfilter = `id:${slugfilter.split("-")[0]}`
    }

    return slugfilter
}

export function getSlugId(slug) {
  var slugid = slug || ''

  if (hasData(slugid)) {
    slugid = `${slugid.split("-")[0]}`
  }

  return slugid
}