import { hasData } from "./hasData"

    export function formatDateRSS(date) {
       
        var vdate = date;
        if (!hasData(vdate)) vdate = +new Date();
        return `${formatDateWeekday(vdate)}, ${formatDateDay(vdate) } ${formatDateMonthYear(vdate) } ${ formatDateTime(vdate)} GMT`
    }

    export function formatDate(date) {
        return new Date(date).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
    }

    export function formatDateWeekday(date) {
        return new Date(date).toLocaleDateString('en-us', {  weekday: 'short' })
    }

    export function formatDateDay(date) {
        return new Date(date).toLocaleDateString('en-us', { day: 'numeric' })
    }

    export function formatDateMonthYear(date) {
        return new Date(date).toLocaleDateString('en-us', {  month: 'short', year: 'numeric' })
    }

    export function formatDateTime(date) {
        return new Date(date).toLocaleTimeString('en-us', {hour12: false})
    }