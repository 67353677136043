import { hasData } from "./hasData";

export function hasNumber(n) {
    if (!hasData(n)) return false
    if (isNaN(n)) return false
    return true
}

export function getNumber(n) {
    if (hasNumber(n)) return parseInt(n)
    return 0
}