import { useContext, useEffect, useRef } from 'react'
import { PlayerContext } from '@/components/player/player-wrapper';
import { getTimestamp } from '../getTimestamp';
import { hasData } from '../hasData';
import { cleanGuestJson } from '../cleanGuestJson';
import { clockToSeconds } from './clockToSeconds';

export default function useTimestamp(posts_data){

  const descriptionRef = useRef(null)
  const {player_state,set_player_state} = useContext(PlayerContext)

  useEffect(()=>{ 
  },[
  ])
  
  useEffect(() => {

    descriptionRef?.current?.addEventListener("mousedown", evalTimestamp(posts_data))
  
    return () => descriptionRef?.current?.removeEventListener("mousedown", evalTimestamp(posts_data))
    }, [descriptionRef?.current])

  const evalTimestamp = (posts_data) => {
      
    document.body.onmousedown = function (e) {
      var elementId = e?.target;

      if ((elementId as HTMLElement)?.getAttribute("data-timestamp")?.toString() == "1") {
        //console.log("contact",(elementId as HTMLElement)?.innerHTML)
        let timestamp = (elementId as HTMLElement)?.innerHTML
        let index = parseInt((elementId as HTMLElement)?.getAttribute("data-timestampindex"))
        let stamp = clockToSeconds(timestamp)

        var broadcast_object = {
          url:'',
          hash:'',
          size:'',
          source:'',
          category:'',
          duration:'',
          end_date:'',
          extention:'',
          post_type:'',
          thumbnail:'',
          start_date:'',
          crdate:'',
          metadata_json:'',
          user_id:0,
          user_name:'',
          timestamp:'',
          avatar_url:'',
          content_id:0,
          content_table:'post',
          guests_json:'[]'
        }

        //this function uses the post_id as a key index to make sure we clicked on the right item (stored on the data-timestampindex attribute in the html)
        //we then filter out all the posts_data to just focus on the correct post
        var found_index = posts_data.findIndex((p)=>p?.post_id == index)
        let posts_data_single = posts_data[found_index]

        console.log(found_index,posts_data_single)

        if (found_index == -1) {
          alert("Timestamps will only work after publishing your draft")
        }

        if (posts_data_single?.content_table=="broadcast") {
          if (hasData(posts_data_single?.broadcast_contents_json)) {
           //console.log("posts_data_single?.broadcast_contents_json",cleanGuestJson(posts_data_single?.broadcast_contents_json))
           broadcast_object = (JSON.parse(cleanGuestJson(posts_data_single?.broadcast_contents_json))[0])
          }
        } else {
          
          if (hasData(posts_data_single?.post_contents_json)) {
           // console.log("posts_data_single?.post_contents_json",cleanGuestJson(posts_data_single?.post_contents_json))
            broadcast_object = (JSON.parse(cleanGuestJson(posts_data_single?.post_contents_json))[0])
            
          }
        }

        startPlayerTimestamp(
          stamp,
          broadcast_object?.url, 
          broadcast_object?.duration, 
          posts_data_single?.post_id,
          broadcast_object?.post_type?.toString()=="2"
            ? posts_data_single?.post_id
            : posts_data_single?.content_id, 
          broadcast_object?.post_type?.toString()=="2"
            ? "post"
            : posts_data_single?.content_table, 
          posts_data_single?.user_id, 
          posts_data_single?.user_name, 
          posts_data_single?.avatar_url, 
          broadcast_object?.post_type?.toString()=="2"
          ? broadcast_object?.crdate
          : broadcast_object?.start_date, 
          posts_data_single?.post_title
        );

      }

    }
  }

  const startPlayerTimestamp = async (
                                      timestamp, 
                                      file, 
                                      duration, 
                                      post_id,
                                      content_id, 
                                      content_table, 
                                      owner_id, 
                                      owner_name, 
                                      avatar_url, 
                                      start_date, 
                                      broadcast_title
                                      ) => {

        await set_player_state({});

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:true
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        }

        // console.log("player_object",player_object,display_data)

        await set_player_state(player_object);

    }

  return {descriptionRef};

}