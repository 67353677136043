
import { hasData } from '@/lib/utils/hasData';
import { heroIcons3 } from './heroicons';

export default function SvgJsxNew({
          type='outline'
        , icon='home'
        , className=''
        , title=''
        , color='currentColor'
    }) {

    if (type=='outline') {

        return (
            <svg 
                role="img"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth={1.5}
                stroke={color}
                className={hasData(className) ? className : "w-6 h-6"}
            >
            <title>{hasData(title) ? title : icon}</title>
            {heroIcons3[icon]?.outline}
            </svg>
             )

    } else if (type=='solid') {

        return (
            <svg 
                role="img"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24"
                fill={color}
                className={hasData(className) ? className : "w-6 h-6"}
            >
            <title>{hasData(title) ? title : icon}</title>
            {heroIcons3[icon]?.solid}
            </svg>
             )
    
    } else { //mini
        
        return (
            <svg 
                role="img"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill={color}
                className={hasData(className) ? className : "w-5 h-5"}
            >
            <title>{hasData(title) ? title : icon}</title>
            {heroIcons3[icon]?.mini}
            </svg>
             )
    }

}
